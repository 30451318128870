import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AdbIcon from '@mui/icons-material/Adb';
import {memo} from "react";
import Box from "@mui/material/Box";
import {useTheme} from "../../hooks/theme";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';
import TranslateIcon from '@mui/icons-material/Translate';
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";


const Footer = memo(() => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();

    const toggleLanguage = () => {
        i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')
    }

    return (
        <Box
            sx={{
                bottom: 0,
                height: '18vh',
                position: 'fixed',
                width: '100%',
                backgroundColor: theme.palette.primary.contrastText,
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
        }}>
            <IconButton onClick={toggleLanguage} sx={{position: 'absolute', bottom: '10%', left: '1%'}}>
                <TranslateIcon />
            </IconButton>
            <Container maxWidth="lg" >
                <Toolbar disableGutters>
                    <Grid container spacing={4} pt={2}>
                        <Grid item xs={6} md={4}>
                            <Box sx={{display: 'flex'}}>
                                <AdbIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    sx={{
                                        mr: 2,
                                        mb:2,
                                        display: {xs: 'none', md: 'flex'},
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    LOGO
                                </Typography>
                            </Box>
                            <Typography fontSize={'14px'}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque aut consequatur cum debitis doloribus et magni molestiae obcaecati quasi repudiandae?
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography align={'center'} width={'60%'} variant={'h6'}>
                                {t('RESOURCES')}
                            </Typography>
                            <Box sx={{display: 'flex', flexDirection: 'column', width: '60%'}}>
                                <Button sx={{textTransform: 'none'}} variant={'text'}>{t('HOW_PLAY')}</Button>
                                <Button sx={{textTransform: 'none'}} variant={'text'}>{t('RULES')}</Button>
                                <Button sx={{textTransform: 'none'}} variant={'text'}>{t('INSTRUCTION')}</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant={'h6'}>
                                {t('CONTACTS')}
                            </Typography>

                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Box sx={{width: '60%'}}>
                                    <Button sx={{textTransform: 'none'}} startIcon={<TelegramIcon />} variant={'text'}>{t('OUR_TELEGRAM')}</Button>
                                    <Button sx={{textTransform: 'none'}} startIcon={<EmailIcon/>} variant={'text'}>Email@gmail.com</Button>
                                </Box>

                                <Typography fontSize={'14px'}>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, assumenda?
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                </Toolbar>
            </Container>
        </Box>
    );
})

export default Footer;