import React from 'react';

interface ImageProps {
    alt: string;
    deck: string;
}

export default function CardImage(props: ImageProps) {
    const {alt, deck} = props;

    return (
        <img
            width={'100%'}
            height={'100%'}
            src={`https://static.farm.thunderstrike.studio/get-rich/${deck}.png`}
            srcSet={`https://static.farm.thunderstrike.studio/get-rich/${deck}.png`}
            alt={alt}
        />
    );
};
