import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {LoginSchema} from "../types/LoginSchema";
import {login} from "../../../service/login";

const initialState = {
    email: '',
    password: '',
    isLoading: false,
    error: '',
} as LoginSchema;

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setEmail: (state: LoginSchema, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setPassword: (state: LoginSchema, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state: LoginSchema) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state: LoginSchema, action) => {
                state.isLoading = false;
            })
            .addCase(login.rejected, (state: LoginSchema, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: loginActions } = loginSlice;
export const { reducer: loginReducer } = loginSlice;
