import { createAsyncThunk } from '@reduxjs/toolkit';
import {$api} from "../api/api";
import {Msg} from "../types/types";
import {ThunkConfig} from "../config/store/StateSchema";

interface changePasswordProps {
    oldPassword: string;
    newPassword: string;
}

export const changePassword = createAsyncThunk<Msg, changePasswordProps, ThunkConfig<string>>(
    'changePassword',
    async (data, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;

        try {

            const response = await $api.put<Msg>('/profile/password', data);

            if (response.status !== 200) {
                throw new Error();
            }

            return response.data;
        } catch (e) {
            console.log(e);
            return rejectWithValue('error');
        }
    },
);
