/* eslint-disable no-useless-escape */
import {Bounce, ToastOptions} from "react-toastify";
import {Player, TradeOffer} from "../types/types";

const streetsNames = [
    {color: '#ffd260', streets: [0, 1, 2, 3], name: 'Рыжие Дубы' },
    {color: '#449aff', streets: [4, 5, 6, 7], name: 'Синие Жуки' },
    {color: '#ffff7c', streets: [8, 9, 10, 11], name: 'Жёлтые Ручьи' },
    {color: '#9bfe40', streets: [12, 13, 14, 15], name: 'Зелёные Холмы' },
    {color: '#83ffff', streets: [16, 17, 18, 19], name: 'Голубые Ели' },
    {color: '#b587de', streets: [20, 21, 22, 23], name: 'Лавандовые Луга' },
]

export const Validator = {
    checkEmail(email: string): number | null {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z]+\.)+[a-zA-Z]{2,8}))$/;
        return re.test(String(email).toLowerCase()) ? null : 1;
    },

    checkPassword(password: string): number | null {
        return password.length < 6 ? 2 : null;
    },

    checkNickname(nickname: string): number | null {
        return nickname.length < 2 ? 4 : null;
    },

    getErrorText(errNum: number | null) : string | null {
        if (errNum === null) {
            return null;
        }
        let result: string = '';
        switch (errNum) {
            case 1: result = 'The e-mail is not correct'
                break;
            case 2: result = 'Password less than 6 symbols'
                break;
            case 3: result = 'Password mismatch'
                break;
            case 4: result = 'Nickname less than 2 symbols'
        }

        return result;
    }
}

export function getCookie(name: string): string {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : '';
}

export function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

/* eslint-enable */

export const getStreetQuarter = (streetIndex: number) => {
    let index = null;
    for (let i = 0; i < 6; i++) {
        if (streetsNames[i].streets.includes(streetIndex)) {
            index = i
        }
    }

    return streetsNames[index];
}

export const marks = [
    'red', 'green', 'black', 'blue', 'yellow', 'purple'
]

export const getPlayersNicknames = (arr: Player[]) => {
    let nicknames = ['', '', '', '', '', '',]
    arr.forEach((player) => {
        nicknames[player.indexPlayer] = player.nickname
    })

    return nicknames;
}

const compareCards = (item: number, arr: number[], length: number) => {
    if (arr === null) {
        return true;
    }

    for (let i = 0; i < length; i++) {
        if (item === arr[i]) {
            return false;
        }
    }
    return true;
}

const deleteEmptyIndustry = (arr: number[]) => {
    const copyArr = arr;
    if (!copyArr) {
        return copyArr;
    }

    for (let i = 0; i < arr.length; i++) {
        if (copyArr[i] === -1) {
            copyArr.splice(i, 1);
        }
    }

    return copyArr;
}

const compareTokens = (itemsArr: number[], offerArr: number[]) => {
    const arr = [
        0, 0, 0, 0, 0,
        0, 0, 0, 0, 0
    ];

    for (let i = 0; i < itemsArr.length; i++) {
        if (itemsArr[i] > offerArr[i]) {
            arr[i] = itemsArr[i] - offerArr[i]
        } else if (itemsArr[i] === offerArr[i])  {
            arr[i] = 0;
        } else {
            arr[i] = itemsArr[i]
        }
    }

    return arr
}

const compareZollars = (itemZollars: number, offerZollars: number) => {
    return itemZollars - offerZollars
}

export const combineCompare = (items: TradeOffer, offer: TradeOffer) => {
    const obj = Object.assign({}, items);
    obj.industries = deleteEmptyIndustry(obj.industries);

    if (obj.handCharacters && offer.handCharacters) {
        obj.handCharacters = obj.handCharacters.filter((item) => compareCards(item, offer.handCharacters, obj.handCharacters.length))
    }
    if (obj.streets && offer.streets) {
        obj.streets = obj.streets.filter((item) => compareCards(item, offer.streets, obj.streets.length))
    }
    if (obj.industries && offer.industries) {
        obj.industries = obj.industries.filter((item) => compareCards(item, offer.industries, obj.industries.length))
    }
    if (obj.tokens && offer.tokens) {
        obj.tokens = compareTokens(items.tokens, offer.tokens)
    }
    obj.zollar = compareZollars(items.zollar, offer.zollar)

    return obj;
}

export const offerIsEmpty = (arr: TradeOffer) => {
    let result = true;

    arr?.tokens.forEach((item) => {
        if (item !== 0) {
            result = false;
        }
    })

    if (arr?.zollar > 0) result = false;
    if (arr?.streets !== null || arr?.streets?.length > 0) result = false;
    if (arr?.handCharacters !== null || arr?.handCharacters?.length > 0) result = false;
    if (arr?.industries !== null || arr?.industries?.length > 0) result = false;

    return result;
}

export const compareOffer = (arr: TradeOffer, currentArr: TradeOffer) => {
    let result = true;

    for (let i = 0; i < 10; i++) {
        if (arr?.tokens[i] !== 0 && arr?.tokens[i] !== currentArr?.tokens[i]) {
            result = false;
        }
    }

    if (!compareIntArr(arr?.streets, currentArr?.streets)) {
        result = false;
    }
    if (!compareIntArr(arr?.handCharacters, currentArr?.handCharacters)) {
        result = false;
    }
    if (!compareIntArr(arr?.industries, currentArr?.industries)) {
        result = false;
    }
    if (arr?.zollar !== currentArr?.zollar) {
        result = false;
    }

    return result;
}

const compareIntArr = (arr1: number[], arr2: number[]) => {
    arr1?.sort()
    arr2?.sort()
    return arr1 + "" === arr2 + ""
}

export const toastOptions: ToastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
}
export const toastOptionsNoClose: ToastOptions = {
    position: "top-center",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
}


export const toastOptionsLighting: ToastOptions = {
    position: "top-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
}
