import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {theme} from "../../config/theme/theme";
import {Button, TextField, Tooltip, Typography} from "@mui/material";
import TradeItem from "./TradeItem";
import {Proposal, TradeBody, TradeOffer} from "../../types/types";
import CardImage from "./CardImage";
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton";
import {combineCompare, compareOffer, getStreetQuarter, offerIsEmpty} from "../../utils/utils";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {useEffect, useState} from "react";
import {trade} from "../../utils/ws";
import ZoomCard from "./ZoomCard";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 6,
    display: 'flex'
};

interface TradeModalProps {
    message: string;
    openModal: boolean;
    sellerOfferData: TradeOffer;
    sellerItemsData: TradeOffer;
    buyerOfferData: TradeOffer;
    buyerItemsData: TradeOffer;
    close: () => void;
    players: string[];
    playerIndex: number;
    reshuffle: boolean;
    accept: boolean;
}

export default function TradeModal(props: TradeModalProps) {
    const {
        message,
        openModal,
        sellerOfferData,
        sellerItemsData,
        buyerItemsData,
        buyerOfferData,
        close,
        players,
        reshuffle,
        playerIndex,
        accept
    } = props;
    const [open, setOpen] = useState<boolean>(false);
    const [sellerItems, setSellerItems] = useState<TradeOffer>(null);
    const [sellerOffer, setSellerOffer] = useState<TradeOffer>(null);
    const [buyerItems, setBuyerItems] = useState<TradeOffer>(null);
    const [buyerOffer, setBuyerOffer] = useState<TradeOffer>(null);
    const [zollarOfferSeller, setZollarSellerOffer] = useState<number>(0);
    const [zollarOfferBuyer, setZollarBuyerOffer] = useState<number>(0);
    const [newMessage, setNewMessage] = useState<string>('');
    const [offerState, setOfferState] = useState<boolean>(false);

    useEffect(() => {
        console.log('props', props);
        setOpen(openModal);
        if (!reshuffle && openModal) {
            setSellerItems(combineCompare(sellerItemsData, sellerOfferData));
            setSellerOffer({...sellerOfferData});
            setZollarSellerOffer(sellerOfferData?.zollar);
            setBuyerItems(combineCompare(buyerItemsData, buyerOfferData));
            setBuyerOffer({...buyerOfferData});
            setZollarBuyerOffer(buyerOfferData?.zollar);
        } else if (reshuffle && openModal) {
            setSellerItems(combineCompare(sellerItemsData, buyerOfferData));
            setSellerOffer({...buyerOfferData});
            setZollarSellerOffer(buyerOfferData?.zollar);
            setBuyerItems(combineCompare(buyerItemsData, sellerOfferData));
            setBuyerOffer({...sellerOfferData});
            setZollarBuyerOffer(sellerOfferData?.zollar);
        }

    }, [openModal, reshuffle]);



    // Sell logic

    const addCardToOfferSeller = (card: number) => {
        setSellerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.handCharacters = prevState?.handCharacters.filter((item) => item !== card);
            return obj;
        })
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            if (obj.handCharacters !== null) {
                obj.handCharacters.push(card);
            } else {
                obj.handCharacters = [card]
            }
            return obj;
        })
        setOfferState(true);
    }

    const removeCardFromOfferSeller = (card: number) => {
        setSellerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.handCharacters.push(card);
            return obj;
        })
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.handCharacters = prevState.handCharacters.filter((item) => item !== card);
            return obj;
        })
        setOfferState(true);
    }


    const addStreetToOfferSeller = (card: number) => {
        setSellerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.streets = prevState.streets.filter((item) => item !== card);
            return obj;
        })
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            if (obj.streets !== null) {
                obj.streets.push(card);
            } else {
                obj.streets = [card]
            }
            return obj;
        })
        setOfferState(true);
    }

    const removeStreetFromOfferSeller = (card: number) => {
        setSellerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.streets.push(card);
            return obj;
        })
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.streets = prevState.streets.filter((item) => item !== card);
            return obj;
        })
        setOfferState(true);
    }

    const addIndustryToOfferSeller = (card: number) => {
        setSellerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.industries = prevState.industries.filter((item) => item !== card);
            return obj;
        })
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            if (obj.industries !== null) {
                obj.industries.push(card);
            } else {
                obj.industries = [card]
            }
            return obj;
        })
        setOfferState(true);
    }

    const removeIndustryFromOfferSeller = (card: number) => {
        setSellerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.industries.push(card);
            return obj;
        })
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.industries = prevState.industries.filter((item) => item !== card);
            return obj;
        })
        setOfferState(true);
    }

    const addTokenToOfferSeller = (indexToken: number) => {
        setSellerItems(prevState => {
            let obj = Object.assign({}, prevState);
            if (obj.tokens[indexToken] === 1) {
                obj.tokens[indexToken] = 0 ;
            } else if (obj.tokens[indexToken] > 1) {
                obj.tokens[indexToken] =  obj.tokens[indexToken] - 1;
            }
            return obj;
        })
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.tokens[indexToken] = obj.tokens[indexToken] + 1;
            return obj;
        })
        setOfferState(true);
    }

    const removeTokenFromOfferSeller = (indexToken: number) => {
        setSellerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.tokens[indexToken] = obj.tokens[indexToken] + 1
            return obj;
        })
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.tokens[indexToken] = obj.tokens[indexToken] - 1;
            return obj;
        })
        setOfferState(true);
    }

    const removeAllTokensFromOfferSeller = (indexToken: number) => {
        setSellerItems(prevState => {
            let obj = Object.assign({}, prevState);
            if (sellerItems.tokens[indexToken] === 0) {
                obj.tokens[indexToken] = sellerOffer.tokens[indexToken];
            } else if (sellerItems.tokens[indexToken] > 0) {
                obj.tokens[indexToken] = sellerOffer.tokens[indexToken] + sellerItems.tokens[indexToken]
            }

            return obj;
        })
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.tokens[indexToken] = 0;
            return obj;
        })
        setOfferState(true);
    }

    const addZollarsToOfferSeller = () => {
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.zollar = 100
            setZollarSellerOffer(100);
            return obj;
        })
        setOfferState(true);
    }

    const removeZollarsFromOfferSeller = () => {
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.zollar = 0
            setZollarSellerOffer(0);
            return obj;
        })
        setOfferState(true);
    }

    const onChangeZollarOfferSeller = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (sellerOffer?.zollar > sellerItems?.zollar) {
            setSellerOffer(prevState => {
                let obj = Object.assign({}, prevState);
                obj.zollar = sellerItems.zollar
                setZollarSellerOffer(sellerItems.zollar);
                return obj;
            })
            setOfferState(true);
        } else {
            setSellerOffer(prevState => {
                let obj = Object.assign({}, prevState);
                obj.zollar = Number(ev.target.value)
                setZollarSellerOffer(Number(ev.target.value));
                return obj;
            })
            setOfferState(true);
        }
    }

    // Buy logic

    const removeCardFromOfferBuyer = (card: number) => {
        setBuyerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.handCharacters = prevState.handCharacters.filter((item) => item !== card);
            return obj;
        })
        setOfferState(true);
    }


    const addStreetToOfferBuyer = (card: number) => {
        setBuyerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.streets = prevState.streets.filter((item) => item !== card);
            return obj;
        })
        setBuyerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            if (obj.streets !== null) {
                obj.streets.push(card);
            } else {
                obj.streets = [card]
            }
            return obj;
        })
        setOfferState(true);
    }

    const removeStreetFromOfferBuyer = (card: number) => {
        setBuyerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.streets.push(card);
            return obj;
        })
        setBuyerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.streets = prevState.streets.filter((item) => item !== card);
            return obj;
        })
        setOfferState(true);
    }

    const addIndustryToOfferBuyer = (card: number) => {
        setBuyerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.industries = prevState.industries.filter((item) => item !== card);
            return obj;
        })
        setBuyerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            if (obj.industries !== null) {
                obj.industries.push(card);
            } else {
                obj.industries = [card]
            }
            return obj;
        })
        setOfferState(true);
    }

    const removeIndustryFromOfferBuyer = (card: number) => {
        setBuyerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.industries.push(card);
            return obj;
        })
        setBuyerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.industries = prevState.industries.filter((item) => item !== card);
            return obj;
        })
        setOfferState(true);
    }

    const addTokenToOfferBuyer = (indexToken: number) => {
        setBuyerItems(prevState => {
            let obj = Object.assign({}, prevState);
            if (obj.tokens[indexToken] === 1) {
                obj.tokens[indexToken] = 0 ;
            } else if (obj.tokens[indexToken] > 1) {
                obj.tokens[indexToken] =  obj.tokens[indexToken] - 1;
            }
            return obj;
        })
        setBuyerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.tokens[indexToken] = obj.tokens[indexToken] + 1;
            return obj;
        })
        setOfferState(true);
    }

    const removeTokenFromOfferBuyer = (indexToken: number) => {
        setBuyerItems(prevState => {
            let obj = Object.assign({}, prevState);
            obj.tokens[indexToken] = obj.tokens[indexToken] + 1
            return obj;
        })
        setBuyerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.tokens[indexToken] = obj.tokens[indexToken] - 1;
            return obj;
        })
        setOfferState(true);
    }

    const removeAllTokensFromOfferBuyer = (indexToken: number) => {
        setBuyerItems(prevState => {
            let obj = Object.assign({}, prevState);
            if (buyerItems.tokens[indexToken] === 0) {
                obj.tokens[indexToken] = buyerOffer.tokens[indexToken];
            } else if (buyerItems.tokens[indexToken] > 0) {
                obj.tokens[indexToken] = buyerOffer.tokens[indexToken] + buyerItems.tokens[indexToken]
            }

            return obj;
        })
        setBuyerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.tokens[indexToken] = 0;
            return obj;
        })
        setOfferState(true);
    }

    const addZollarsToOfferBuyer = () => {
        setBuyerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.zollar = 100
            setZollarBuyerOffer(100);
            return obj;
        })
        setOfferState(true);
    }

    const removeZollarsFromOfferBuyer = () => {
        setBuyerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.zollar = 0
            setZollarBuyerOffer(0);
            return obj;
        })
        setOfferState(true);
    }

    const onChangeZollarOfferBuyer = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSellerOffer(prevState => {
            let obj = Object.assign({}, prevState);
            obj.zollar = Number(ev.target.value)
            setZollarBuyerOffer(Number(ev.target.value));
            return obj;
        })
        setOfferState(true);
    }

    const sendOffer = () => {
        const offerBody: TradeBody = {
            bayer: reshuffle ? buyerItemsData.player : playerIndex,
            exchange: reshuffle ? {...buyerOffer, zollar: zollarOfferBuyer} : {...sellerOffer, zollar: zollarOfferSeller},
            seller: reshuffle ? playerIndex : buyerItemsData.player,
            target: reshuffle ? {...sellerOffer, zollar: zollarOfferSeller} : {...buyerOffer, zollar: zollarOfferBuyer}
        }

        const offer: Proposal = {
            count: '',
            message: newMessage,
            status: 0,
            tradeOffer: offerBody,
            tradeProduct: {...buyerItemsData, player: buyerItemsData.player}
        }

        trade(offer, 'proposal');
        close();
        setOfferState(false);
    }

    const sendPreOffer = () => {
        const offerBody: TradeBody = {
            bayer: reshuffle ? buyerItemsData.player : playerIndex,
            exchange: reshuffle ? buyerOffer : sellerOffer,
            seller: reshuffle ? playerIndex : buyerItemsData.player,
            target: reshuffle ? sellerOffer : buyerOffer
        }

        const offer: Proposal = {
            count: '',
            message: newMessage,
            status: 1,
            tradeOffer: offerBody,
            tradeProduct: {...buyerItemsData, player: buyerItemsData.player}
        }

        trade(offer, 'preliminary');
        close();
        setOfferState(false);
    }

    const canselOffer = () => {
        const offerBody: TradeBody = {
            bayer: reshuffle ? buyerItemsData.player : playerIndex,
            exchange: sellerOffer,
            seller: reshuffle ? playerIndex : buyerItemsData.player,
            target: buyerOffer
        }

        const offer: Proposal = {
            count: '',
            message: newMessage,
            status: 2,
            tradeOffer: offerBody,
            tradeProduct: {...buyerItemsData, player: 0}
        }

        trade(offer, 'proposal');
        close();
        setOfferState(false);
    }

    const showOfferBtn = () => {
        let result = sendBtn(sendOffer, false, 'Send offer');

        if (accept && !offerState) {
            result = sendBtn(sendOffer, false, 'Accept offer');
        }

        if (offerIsEmpty(sellerOffer) && offerIsEmpty(buyerOffer)) {
            result = sendBtn(sendOffer, true, 'Send offer');
        }

        if (offerIsEmpty(sellerOffer) && !offerIsEmpty(buyerOffer) && !accept) {
            result = sendBtn(sendPreOffer, false, 'Send pre offer');
        }

        if (!offerIsEmpty(sellerOffer) && offerIsEmpty(buyerOffer) && !accept) {
            result = sendBtn(sendPreOffer, false, 'Send pre offer');
        }

        if (sellerOffer?.zollar > sellerItems?.zollar) {
            result = sendBtnTooltip(sendOffer, true, 'Send offer');
        }

        return result;
    }

    const sendBtnTooltip = (func: () => void, disabled: boolean, text: string) => {
        return (
            <Tooltip placement="top" title={'Вам не хватает золларов!'}>
                <Button
                    disabled={disabled}
                    onClick={() => func()}
                    variant={'contained'}
                >
                    {text}
                </Button>
            </Tooltip>
        )
    };
    const sendBtn = (func: () => void, disabled: boolean, text: string) => {
        return (
            <Button
                disabled={disabled}
                onClick={() => func()}
                variant={'contained'}
            >
                {text}
            </Button>
        )
    };


    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{width: '20%'}}>
                        <Box sx={{width: '100%', pt: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', bgcolor: theme.palette.success.main}}>
                            <Typography variant={'h5'}>{players ? players[playerIndex]: null}</Typography>
                            <Box sx={{border: '1px solid #1F2D3D', width: '50%', mt: 1}}/>
                        </Box>
                        <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: theme.palette.success.main}}>
                            <Typography mb={2} align={'center'} variant={'h6'}>Your items</Typography>
                            <TradeItem addFunc={addStreetToOfferSeller} arr={sellerItems?.streets} type={'street'} title={'Streets'} />
                            <TradeItem addFunc={addIndustryToOfferSeller} arr={sellerItems?.industries} type={'building'} title={'Buildings'} />
                            <TradeItem addFunc={addCardToOfferSeller} arr={sellerItems?.handCharacters} type={'characters'} title={'Cards'} />
                            <TradeItem addFunc={addTokenToOfferSeller} arr={sellerItems?.tokens} type={'tokens'} title={'Tokens'} />
                            <Button
                                disableElevation
                                onClick={() => addZollarsToOfferSeller()}
                                sx={{
                                    width: '100%',
                                    height: '50px',
                                    borderTop: '2px solid #E2D6CE',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    fontSize: 20,
                                    borderRadius: 0,
                                    cursor: 'pointer',
                                    bgcolor: theme.palette.primary.main,
                                    color: 'white',
                                    '&:hover': {
                                        color: 'white',
                                        backgroundColor: theme.palette.primary.main,
                                    }
                                }}
                            >
                                <Typography fontSize={20} sx={{ml: 2, textTransform: 'none'}}>Zollars</Typography>
                                <Typography fontSize={20} sx={{mr: 2, textTransform: 'none'}}>{sellerItems?.zollar}</Typography>
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{
                        width: '60%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <Box
                            sx={{
                                width: '50%',
                                height: '200px',
                                bgcolor: theme.palette.primary.contrastText,
                                borderEndStartRadius: '15%',
                                borderEndEndRadius: '15%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                            <Box sx={{display: 'flex'}}>
                                <Typography mr={1} color={theme.palette.primary.main} fontSize={18} align={'center'} sx={{mb: 2, mt: 4}}>{message !== '' && players ? `${players[buyerItemsData?.player]}: ` : null}</Typography>
                                <Typography fontSize={18} align={'center'} sx={{mb: 2, mt: 4}}>{message !== '' && players ? message : null}</Typography>
                            </Box>
                            <TextField
                                sx={{width: '80%', mb: 5}}
                                value={newMessage}
                                onChange={(e) => {
                                    setNewMessage(e.target.value)
                                }}
                                type={'text'}
                                variant={'standard'}
                                placeholder={'Enter message to offer'}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: '90%',
                                height: '725px',
                                bgcolor: theme.palette.primary.light,
                                borderStartStartRadius: '5%',
                                borderStartEndRadius: '5%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 4,}}>
                                <Box sx={{width: '45%', height: '550px', border: '1px solid #384B52'}}>
                                    <Typography sx={{borderBottom: '1px solid #384B52', height: '7%'}} fontSize={20} align={'center'}>Sell</Typography>
                                    <Box sx={{display: 'flex', flexDirection: 'column', height: '92.7%', overflow: 'auto'}}>
                                        {
                                            sellerOffer?.handCharacters?.map((item) => (
                                                <Box key={item} sx={{height: '120px', pt: 1, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>
                                                    <Box sx={{width: '70px', height: '100px', ml: 2}}>
                                                        <ZoomCard card={<CardImage alt={'character'} deck={`deck-characters/${item}`}/>} />
                                                    </Box>
                                                    <IconButton onClick={() => removeCardFromOfferSeller(item)} sx={{mr: 2}}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </Box>
                                            ))
                                        }
                                        {
                                            sellerOffer?.industries?.map((item) => (
                                                <Box key={item} sx={{height: '120px', pt: 1, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>
                                                    <Box sx={{width: '70px', height: '100px', ml: 2}}>
                                                        <ZoomCard card={<CardImage alt={'industry'} deck={`deck-industry/${item}`}/>} />
                                                    </Box>
                                                    <IconButton onClick={() => removeIndustryFromOfferSeller(item)} sx={{mr: 2}}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </Box>
                                            ))
                                        }
                                        {
                                            sellerOffer?.streets?.map((item) => (
                                                <Box key={item} sx={{
                                                    height: '120px',
                                                    pt: 1,
                                                    pb: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    bgcolor: 'rgba(0, 0, 0, 0.1)',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.4)'
                                                }}>
                                                    <Box
                                                        sx={{
                                                            width: '70px',
                                                            height: '100px',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            ml: 2,
                                                            bgcolor: getStreetQuarter(item).color
                                                        }}
                                                    >
                                                        <Typography align={'center'}
                                                                    sx={{wordBreak: 'break-word', pl: 1, pr: 1}}
                                                                    fontSize={9}>
                                                            {getStreetQuarter(item).name}
                                                        </Typography>
                                                        <Typography align={'center'}
                                                                    sx={{wordBreak: 'break-word', pl: 1, pr: 1}}
                                                                    fontSize={9}>
                                                            {`№${item}`}
                                                        </Typography>
                                                    </Box>
                                                    <IconButton onClick={() => removeStreetFromOfferSeller(item)} sx={{mr: 2}}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </Box>
                                            ))
                                        }
                                        {
                                            sellerOffer?.tokens?.map((item, index) => (
                                                item > 0 ?
                                                    <Box key={index} sx={{height: '120px', pt: 1, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>
                                                        <Box sx={{display: 'flex'}}>
                                                            <Box sx={{width: '75px', height: '75px', ml: 2}}>
                                                                <CardImage alt={'token'} deck={`tokens/${index}`}/>
                                                            </Box>
                                                            <Box sx={{ml: 5, display: 'flex', alignItems: 'center'}}>
                                                                <IconButton onClick={() => removeTokenFromOfferSeller(index)}>
                                                                    <RemoveCircleIcon fontSize={'medium'}/>
                                                                </IconButton>
                                                                <Typography sx={{ml: 1}} fontSize={16}> {item} / {sellerItems.tokens[index] + item}</Typography>
                                                                <IconButton disabled={sellerItems.tokens[index] === 0} onClick={() => addTokenToOfferSeller(index)} sx={{ml: 1}}>
                                                                    <AddCircleIcon fontSize={'medium'} />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                        <IconButton onClick={() => removeAllTokensFromOfferSeller(index)} sx={{mr: 2}}>
                                                            <ClearIcon/>
                                                        </IconButton>
                                                    </Box>
                                                : null
                                            ))
                                        }
                                        {
                                            zollarOfferSeller
                                                ? <Box sx={{height: '120px', pt: 1, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>
                                                    <Box sx={{height: '100px', width: '250px', display: 'flex', alignItems: 'center', ml: 2}}>
                                                        <TextField
                                                            sx={{width: '150px'}}
                                                            variant={'standard'}
                                                            onChange={(e) => onChangeZollarOfferSeller(e)}
                                                            type={'number'}
                                                            placeholder={'Zollars'}
                                                            value={zollarOfferSeller}
                                                            label="Zollars"
                                                            InputProps={{ inputProps: { min: 1, max: sellerItems?.zollar } }}
                                                        />
                                                        <Typography mt={2} fontSize={22} sx={{ml: 2}}>/ {sellerItems?.zollar}</Typography>
                                                    </Box>
                                                    <IconButton onClick={() => removeZollarsFromOfferSeller()} sx={{mr: 2}}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </Box>
                                                : null
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{width: '45%', height: '550px', border: '1px solid #384B52', borderLeft: 'none'}}>
                                    <Typography sx={{ height: '7%', borderBottom: '1px solid #384B52'}} fontSize={20} align={'center'}>Buy</Typography>
                                    <Box sx={{display: 'flex', flexDirection: 'column', height: '92.7%', overflow: 'auto'}}>
                                        {
                                            buyerOffer?.handCharacters?.map((item) => (
                                                <Box key={item} sx={{height: '120px', pt: 1, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>
                                                    <Box sx={{width: '70px', height: '100px', ml: 2}}>
                                                        <ZoomCard card={<CardImage alt={'character'} deck={`deck-characters/${item}`}/>} />
                                                    </Box>
                                                    <IconButton onClick={() => removeCardFromOfferBuyer(item)} sx={{mr: 2}}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </Box>
                                            ))
                                        }
                                        {
                                            buyerOffer?.industries?.map((item) => (
                                                <Box key={item} sx={{height: '120px', pt: 1, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>
                                                    <Box sx={{width: '70px', height: '100px', ml: 2}}>
                                                        <ZoomCard card={<CardImage alt={'industry'} deck={`deck-industry/${item}`}/>} />
                                                    </Box>
                                                    <IconButton onClick={() => removeIndustryFromOfferBuyer(item)} sx={{mr: 2}}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </Box>
                                            ))
                                        }
                                        {
                                            buyerOffer?.streets?.map((item) => (
                                                <Box key={item} sx={{
                                                    height: '120px',
                                                    pt: 1,
                                                    pb: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    bgcolor: 'rgba(0, 0, 0, 0.1)',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.4)'
                                                }}>
                                                    <Box
                                                        sx={{
                                                            width: '70px',
                                                            height: '100px',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            ml: 2,
                                                            bgcolor: getStreetQuarter(item).color
                                                        }}
                                                    >
                                                        <Typography align={'center'}
                                                                    sx={{wordBreak: 'break-word', pl: 1, pr: 1}}
                                                                    fontSize={9}>
                                                            {getStreetQuarter(item).name}
                                                        </Typography>
                                                        <Typography align={'center'}
                                                                    sx={{wordBreak: 'break-word', pl: 1, pr: 1}}
                                                                    fontSize={9}>
                                                            {`№${item}`}
                                                        </Typography>
                                                    </Box>
                                                    <IconButton onClick={() => removeStreetFromOfferBuyer(item)} sx={{mr: 2}}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </Box>
                                            ))
                                        }
                                        {
                                            buyerOffer?.tokens?.map((item, index) => (
                                                item > 0 ?
                                                    <Box key={index} sx={{height: '120px', pt: 1, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>
                                                        <Box sx={{display: 'flex'}}>
                                                            <Box sx={{width: '75px', height: '75px', ml: 2}}>
                                                                <CardImage alt={'token'} deck={`tokens/${index}`}/>
                                                            </Box>
                                                            <Box sx={{ml: 5, display: 'flex', alignItems: 'center'}}>
                                                                <IconButton onClick={() => removeTokenFromOfferBuyer(index)}>
                                                                    <RemoveCircleIcon fontSize={'medium'}/>
                                                                </IconButton>
                                                                <Typography sx={{ml: 1}} fontSize={16}> {item} / {buyerItems.tokens[index] + item}</Typography>
                                                                <IconButton disabled={buyerItems.tokens[index] === 0} onClick={() => addTokenToOfferBuyer(index)} sx={{ml: 1}}>
                                                                    <AddCircleIcon fontSize={'medium'} />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                        <IconButton onClick={() => removeAllTokensFromOfferBuyer(index)} sx={{mr: 2}}>
                                                            <ClearIcon/>
                                                        </IconButton>
                                                    </Box>
                                                    : null
                                            ))
                                        }
                                        {
                                            zollarOfferBuyer
                                                ? <Box sx={{height: '120px', pt: 1, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>
                                                    <Box sx={{height: '100px', width: '250px', display: 'flex', alignItems: 'center', ml: 2}}>
                                                        <TextField
                                                            sx={{width: '150px'}}
                                                            variant={'standard'}
                                                            onChange={(e) => onChangeZollarOfferBuyer(e)}
                                                            type={'number'}
                                                            placeholder={'Zollars'}
                                                            value={zollarOfferBuyer}
                                                            label="Zollars"
                                                            InputProps={{ inputProps: { min: 1 } }}
                                                        />
                                                        <Typography mt={2} fontSize={22} sx={{ml: 2}}>/ ???</Typography>
                                                    </Box>
                                                    <IconButton onClick={() => removeZollarsFromOfferBuyer()} sx={{mr: 2}}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </Box>
                                                : null
                                        }
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{width: '100%', height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Button onClick={() => canselOffer()} variant={'outlined'} sx={{mr: 8}}>Cancel trade</Button>
                                {showOfferBtn()}
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{width: '20%'}}>
                        <Box sx={{width: '100%', pt: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', bgcolor: theme.palette.success.main}}>
                            <Typography variant={'h5'}>{players ? players[buyerItems?.player] : null}</Typography>
                            <Box sx={{border: '1px solid #1F2D3D', width: '50%', mt: 1}}/>
                        </Box>
                        <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: theme.palette.success.main}}>
                            <Typography mb={2} align={'center'} variant={'h6'}>{players ? players[buyerItems?.player] : null} items</Typography>
                            <TradeItem addFunc={addStreetToOfferBuyer} arr={buyerItems?.streets} type={'street'} title={'Streets'} />
                            <TradeItem addFunc={addIndustryToOfferBuyer} arr={buyerItems?.industries} type={'building'} title={'Buildings'} />
                            <TradeItem addFunc={addTokenToOfferBuyer} arr={buyerItems?.tokens} type={'tokens'} title={'Tokens'} />
                            <Button
                                disableElevation
                                onClick={() => addZollarsToOfferBuyer()}
                                sx={{
                                    width: '100%',
                                    height: '50px',
                                    borderTop: '2px solid #E2D6CE',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    fontSize: 20,
                                    borderRadius: 0,
                                    cursor: 'pointer',
                                    bgcolor: theme.palette.primary.main,
                                    color: 'white',
                                    '&:hover': {
                                        color: 'white',
                                        backgroundColor: theme.palette.primary.main,
                                    }
                                }}
                            >
                                <Typography fontSize={20} sx={{ml: 2, textTransform: 'none'}}>Zollars</Typography>
                                <Typography fontSize={20} sx={{mr: 2, textTransform: 'none'}}>?</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}