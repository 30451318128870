import { RouteProps } from 'react-router-dom';
import MainPage from "../../pages/main";
import Login from "../../pages/login";
import Signup from "../../pages/signup";
import Profile from "../../pages/profile";
import About from "../../pages/about";
import Room from "../../pages/room";
import NotFound from "../../pages/notFound";

export enum AppRoutes {
    MAIN = 'main',
    LOGIN = 'login',
    SIGNUP = 'signup',
    PROFILE = 'profile',
    ABOUT = 'about',
    ROOM = 'room',
    // last
    NOT_FOUND = 'not_found',
}

export enum NotAuthAppRoutes {
    LOGIN = 'login',
    SIGNUP = 'signup',
    // last
    NOT_FOUND = 'not_found',
}

export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.MAIN]: '/',
    [AppRoutes.LOGIN]: '/login',
    [AppRoutes.SIGNUP]: '/signup',
    [AppRoutes.PROFILE]: '/profile',
    [AppRoutes.ROOM]: '/room/',
    [AppRoutes.ABOUT]: '/about',
    [AppRoutes.NOT_FOUND]: '*',
};

export const NotAuthRoutePath: Record<NotAuthAppRoutes, string> = {
    [AppRoutes.LOGIN]: '/login',
    [AppRoutes.SIGNUP]: '/signup',
    [AppRoutes.NOT_FOUND]: '*',
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
    [AppRoutes.MAIN]: {
        path: RoutePath.main,
        element: <MainPage />,
    },
    [AppRoutes.LOGIN]: {
        path: RoutePath.login,
        element: <Login />,
    },
    [AppRoutes.SIGNUP]: {
        path: RoutePath.signup,
        element: <Signup />,
    },
    [AppRoutes.PROFILE]: {
        path: RoutePath.profile,
        element: <Profile />,
    },
    [AppRoutes.ABOUT]: {
        path: RoutePath.about,
        element: <About />,
    },
    [AppRoutes.ROOM]: {
        path: `${RoutePath.room}:data`,
        element: <Room />,
    },
    [AppRoutes.NOT_FOUND]: {
        path: RoutePath.not_found,
        element: <NotFound />,
    },
};

export const notAuthRouteConfig: Record<NotAuthAppRoutes, RouteProps> = {
    [NotAuthAppRoutes.LOGIN]: {
        path: NotAuthRoutePath.login,
        element: <Login />,
    },
    [NotAuthAppRoutes.SIGNUP]: {
        path: NotAuthRoutePath.signup,
        element: <Signup />,
    },
    [NotAuthAppRoutes.NOT_FOUND]: {
        path: NotAuthRoutePath.not_found,
        element: <NotFound />,
    },
};
