import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Button} from "@mui/material";
import {assassinAction, fireWorker, tricksterAction} from "../../utils/ws";
import {ReactNode} from "react";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end'
};

interface ZoomCardProps {
    card: ReactNode;
}

export default function ZoomCard({card}: ZoomCardProps) {
    const [open, setOpen] = React.useState(false)


    function handleOpen() {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Box className={'zoomCard'} sx={{cursor: 'pointer', zIndex: 100, width: '100%', height: '100%'}} onClick={handleOpen}>
                {card}
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {card}
                </Box>
            </Modal>
        </div>
    );
}