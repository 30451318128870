import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Button, TextField, Typography} from "@mui/material";
import {Token} from "../../types/types";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "react";
import {
    blackBrokerOffer, pass,
} from "../../utils/ws";
import {theme} from "../../config/theme/theme";
import TradeItem from "./TradeItem";
import CardImage from "./CardImage";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 6,
    display: 'flex'
};

const initialPlayerTokens = [
    0, 0, 0, 0, 0,
    0, 0, 0, 0, 0
]

interface BlackBrokerModalProps {
    balance: number;
    tokens: Array<number>;
    indexPlayer: number;
    indexOpponent: number;
    data: Array<Token>;
    openBroker: boolean;
    nicknames: string[];
}

const playerTokensData = [
    2, 0, 0, 0, 0,
    0, 5, 0, 4, 0,
]

const tokensInfoData: Token[] = [
    {buy: 3000, sell: 1, quantity: 1, dividends: 5}, {buy: 55, sell: 1, quantity: 1, dividends: 15},
    {buy: 60, sell: 1, quantity: 1, dividends: 3}, {buy: 33, sell: 1, quantity: 1, dividends: 3},
    {buy: 101, sell: 1, quantity: 1, dividends: 10}, {buy: 42, sell: 1, quantity: 1, dividends: 44},
    {buy: 36, sell: 1, quantity: 1, dividends: 3}, {buy: 29, sell: 1, quantity: 1, dividends: 21},
    {buy: 88, sell: 1, quantity: 1, dividends: 8}, {buy: 15, sell: 1, quantity: 1, dividends: 45},
]

export default function BlackBrokerModal(props: BlackBrokerModalProps) {
    const {
        balance,
        tokens,
        indexPlayer,
        data,
        openBroker,
        indexOpponent,
        nicknames
    } = props;
    const [open, setOpen] = React.useState(false);
    const [tokensInfo, setTokensInfo] = useState<Array<Token>>(tokensInfoData);
    const [playerTokens, setPlayerTokens] = useState<Array<number>>(playerTokensData);
    const [offerTokens, setOfferTokens] = useState<Array<number>>(initialPlayerTokens);
    const [offerBalance, setOfferBalance] = useState<number>(null);
    const [count, setCount] = useState<number>(6);

    useEffect(() => {
        console.log('props', props)
        setTokensInfo(data);
        setPlayerTokens(tokens);
        setOpen(openBroker)
    }, [openBroker, indexPlayer, tokens, indexOpponent]);

    const handleClose = () => {
        setOpen(false);
    };

    const addTokenToOfferBuyer = (indexToken: number) => {
        if (count === 0) {
            return;
        }

        setPlayerTokens(prevState =>
            prevState.map((item, index) => (
                index === indexToken && item > 0 ? item - 1 : item
            ))
        )
        setOfferTokens(prevState =>
            prevState.map((item, index) => (
                index === indexToken ? item + 1 : item
            ))
        )
        setOfferBalance(prevState => prevState + tokensInfo[indexToken].buy);
        setCount(count - 1);
    }

    const removeTokenFromOfferBuyer = (indexToken: number) => {
        setPlayerTokens(prevState =>
            prevState.map((item, index) => (
                index === indexToken ? item + 1 : item
            ))
        )
        setOfferTokens(prevState =>
            prevState.map((item, index) => (
                index === indexToken ? item - 1 : item
            ))
        )
        setOfferBalance(offerBalance - tokensInfo[indexToken].buy);
        setCount(count + 1);
    }

    const removeAllTokensFromOfferBuyer = (indexToken: number) => {
        setPlayerTokens(prevState =>
            prevState.map((item, index) => (
                index === indexToken ? offerTokens[indexToken] + playerTokens[indexToken] : item
            ))
        )

        setOfferBalance(offerBalance - (tokensInfo[indexToken].buy * offerTokens[indexToken]));
        setCount(count + offerTokens[indexToken])

        setOfferTokens(prevState =>
            prevState.map((item, index) => (
                index === indexToken ? 0 : item
            ))
        )
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{width: '20%'}}>
                        <Box sx={{width: '100%', pt: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', bgcolor: theme.palette.success.main}}>
                            <Typography variant={'h5'}>{nicknames ? nicknames[indexPlayer] : null}</Typography>
                            <Box sx={{border: '1px solid #1F2D3D', width: '50%', mt: 1}}/>
                        </Box>
                        <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: theme.palette.success.main}}>
                            <Typography mb={2} align={'center'} variant={'h6'}>Your Balance</Typography>
                            <Button
                                disableElevation
                                sx={{
                                    width: '100%',
                                    height: '50px',
                                    borderTop: '2px solid #E2D6CE',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    fontSize: 20,
                                    borderRadius: 0,
                                    cursor: 'pointer',
                                    bgcolor: theme.palette.primary.main,
                                    color: 'white',
                                    '&:hover': {
                                        color: 'white',
                                        backgroundColor: theme.palette.primary.main,
                                    }
                                }}
                            >
                                <Typography fontSize={20} sx={{ml: 2, textTransform: 'none'}}>Zollars</Typography>
                                <Typography fontSize={20} sx={{mr: 2, textTransform: 'none'}}>{balance}</Typography>
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{
                        width: '60%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <Box
                            sx={{
                                width: '50%',
                                height: '100px',
                                bgcolor: theme.palette.primary.contrastText,
                                borderEndStartRadius: '15%',
                                borderEndEndRadius: '15%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                            <Box sx={{display: 'flex'}}>
                                <Typography mr={1} color={theme.palette.primary.main} fontSize={18} align={'center'} sx={{mb: 2, mt: 4}}>Доступное кол-во токенов</Typography>
                                <Typography fontSize={18} align={'center'} sx={{mb: 2, mt: 4}}>{count}</Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '90%',
                                height: '725px',
                                bgcolor: theme.palette.primary.light,
                                borderStartStartRadius: '5%',
                                borderStartEndRadius: '5%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 4,}}>
                                <Box sx={{width: '45%', height: '550px', border: '1px solid #384B52'}}>
                                    <Typography sx={{borderBottom: '1px solid #384B52', height: '7%'}} fontSize={20} align={'center'}>Sell</Typography>
                                    <Box sx={{display: 'flex', flexDirection: 'column', height: '92.7%', overflow: 'auto'}}>
                                        {
                                            offerBalance
                                                ? <Box sx={{height: '120px', pt: 1, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>
                                                    <Box sx={{height: '100px', width: '250px', display: 'flex', alignItems: 'center', ml: 2}}>
                                                        <TextField
                                                            sx={{width: '150px'}}
                                                            variant={'standard'}
                                                            disabled={true}
                                                            type={'number'}
                                                            placeholder={'Zollars'}
                                                            value={offerBalance}
                                                            label="Zollars"
                                                            InputProps={{ inputProps: { min: 1, max: balance } }}
                                                        />
                                                        <Typography mt={2} fontSize={22} sx={{ml: 2}}>/ {balance}</Typography>
                                                    </Box>
                                                    <IconButton disabled={true} sx={{mr: 2}}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </Box>
                                                : null
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{width: '45%', height: '550px', border: '1px solid #384B52', borderLeft: 'none'}}>
                                    <Typography sx={{ height: '7%', borderBottom: '1px solid #384B52'}} fontSize={20} align={'center'}>Buy</Typography>
                                    <Box sx={{display: 'flex', flexDirection: 'column', height: '92.7%', overflow: 'auto'}}>
                                        {
                                            offerTokens?.map((item, index) => (
                                                item > 0 ?
                                                    <Box key={index} sx={{height: '120px', pt: 1, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>
                                                        <Box sx={{display: 'flex'}}>
                                                            <Box sx={{width: '75px', height: '75px', ml: 2}}>
                                                                <CardImage alt={'token'} deck={`tokens/${index}`}/>
                                                            </Box>
                                                            <Box sx={{ml: 5, display: 'flex', alignItems: 'center'}}>
                                                                <IconButton onClick={() => removeTokenFromOfferBuyer(index)}>
                                                                    <RemoveCircleIcon fontSize={'medium'}/>
                                                                </IconButton>
                                                                <Typography sx={{ml: 1}} fontSize={16}> {item} / {playerTokens[index] + item}</Typography>
                                                                <IconButton disabled={playerTokens[index] === 0 || count === 0} onClick={() => addTokenToOfferBuyer(index)} sx={{ml: 1}}>
                                                                    <AddCircleIcon fontSize={'medium'} />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                        <IconButton onClick={() => removeAllTokensFromOfferBuyer(index)} sx={{mr: 2}}>
                                                            <ClearIcon/>
                                                        </IconButton>
                                                    </Box>
                                                    : null
                                            ))
                                        }
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{width: '100%', height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Button onClick={() => blackBrokerOffer(-1, initialPlayerTokens)} variant={'outlined'} sx={{mr: 8}}>Cancel trade</Button>
                                <Button disabled={balance < offerBalance} onClick={() => blackBrokerOffer(indexOpponent, offerTokens)} variant={'contained'}>Accept trade</Button>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{width: '20%'}}>
                        <Box sx={{width: '100%', pt: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', bgcolor: theme.palette.success.main}}>
                            <Typography variant={'h5'}>{nicknames ? nicknames[indexOpponent] : null}</Typography>
                            <Box sx={{border: '1px solid #1F2D3D', width: '50%', mt: 1}}/>
                        </Box>
                        <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: theme.palette.success.main}}>
                            <Typography mb={2} align={'center'} variant={'h6'}>{nicknames ? `${nicknames[indexOpponent]} items` : null}</Typography>
                            <TradeItem addFunc={addTokenToOfferBuyer} arr={playerTokens} type={'tokens'} title={'Tokens'} tokensData={tokensInfo} />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}