import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            light: '#E2D6CE',
            main: '#6D6D6E',
            dark: '#E3A061',
            contrastText: '#D8E6EF',
        },
        secondary: {
            light: '#8CAEC9',
            main: '#1874A0',
            dark: '#1F2D3D',
            contrastText: '#384B52',
        },
        success: {
            main: '#FCEDB2',
        },
    },
});