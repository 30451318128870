import { createAsyncThunk } from '@reduxjs/toolkit';
import {userActions} from "../config/store/slices/userSlice";
import {$api} from "../api/api";
import {ThunkConfig} from "../config/store/StateSchema";
import {Msg} from "../types/types";

interface SignupProps {
    email: string;
    nickname: string;
    password: string;
}

export const signup = createAsyncThunk<Msg, SignupProps, ThunkConfig<Msg>>(
    'signup',
    async (authData, thunkAPI) => {
        const { dispatch, rejectWithValue } = thunkAPI;

        try {
            const response = await $api.post<Msg>('/signup', authData);

            if (!response.data) {
                throw new Error();
            }

            dispatch(userActions.initAuthData());

            return response.data;
        } catch (e) {
            return rejectWithValue(e.response.data.msg);
        }
    },
);
