import axios from "axios";

console.log('update 6')

export const __API_PROD__ = `${window.location.origin}/api`;
export const __API_DEV__ = `${window.location.origin}/api`;
export const __API_LOCAL__ = '/';

let API: string;

if (process.env.NODE_ENV === 'development') {
    API = __API_LOCAL__
} else if (!process.env.NODE_ENV) {
    API = __API_LOCAL__
} else {
    API = __API_PROD__
}


axios.defaults.baseURL = API

export const $api = axios.create({
    baseURL: API,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': '*',
    }
});
