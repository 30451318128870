import React, {memo, useCallback} from "react";
import {Box, Button, Container, CssBaseline, Grid, TextField, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {useTheme} from "../hooks/theme";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../hooks/redux";
import {useSelector} from "react-redux";
import {getLoginEmail} from "../config/store/selectors/login/getLoginEmail";
import {getLoginPassword} from "../config/store/selectors/login/getLoginPassword";
import {getLoginIsLoading} from "../config/store/selectors/login/getLoginIsLoading";
import {getLoginError} from "../config/store/selectors/login/getLoginError";
import {loginActions} from "../config/store/slices/loginSlice";
import {login} from "../service/login";


const Login = memo(() => {
    const theme = useTheme()
    const { t } = useTranslation()
    const dispatch = useAppDispatch();
    const email = useSelector(getLoginEmail);
    const password = useSelector(getLoginPassword);
    const isLoading = useSelector(getLoginIsLoading);
    const error = useSelector(getLoginError);
    const navigate = useNavigate();

    const onChangeEmail = useCallback((value: string) => {
        dispatch(loginActions.setEmail(value));
    }, [dispatch]);

    const onChangePassword = useCallback((value: string) => {
        dispatch(loginActions.setPassword(value));
    }, [dispatch]);

    const onLoginClick = useCallback(async () => {
        const result = await dispatch(login({ email, password }));
        if (result.payload !== 'error') {
            navigate('/')
        }
    },  [dispatch, password, email, navigate]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    {t('SIGN_IN')}
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        type={"text"}
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={(e) => onChangeEmail(e.target.value)}
                        value={email}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name={t('PASSWORD')}
                        label={t('PASSWORD')}
                        type="password"
                        value={password}
                        onChange={(e) => onChangePassword(e.target.value)}
                        id="password"
                        autoComplete="current-password"
                    />
                    {error ? <Typography color={'error'} sx={{mt: 1}}>{error}</Typography> : null}
                    <Button
                        id="loginSignIn"
                        type="submit"
                        fullWidth
                        onClick={onLoginClick}
                        disabled={isLoading}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {t('SIGN_IN')}
                    </Button>
                    <Grid container>
                        <Grid item sx={{textAlign: 'end', width: '100%'}}>
                            <Typography component={Link} to={'/signup'} sx={{textDecoration: 'none', color: theme.palette.primary.main}}>{t('LINK_TO_SIGNUP')}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
});

export default Login;