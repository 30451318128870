import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ChangePassSchema} from "../types/ChangePassSchema";
import {changePassword} from "../../../service/changePassword";

const initialState: ChangePassSchema = {
    newPassword: '',
    oldPassword: '',
    isLoading: false,
    error: '',
};


export const changePassSlice = createSlice({
    name: 'changePass',
    initialState,
    reducers: {
        setOldPassword: (state: ChangePassSchema, action: PayloadAction<string>) => {
            state.oldPassword = action.payload;
        },
        setNewPassword: (state: ChangePassSchema, action: PayloadAction<string>) => {
            state.newPassword = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(changePassword.pending, (state: ChangePassSchema) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(changePassword.fulfilled, (state: ChangePassSchema, action) => {
                state.isLoading = false;
            })
            .addCase(changePassword.rejected, (state: ChangePassSchema, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: changePassActions } = changePassSlice;
export const { reducer: changePassReducer } = changePassSlice;
