import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {TextField, Typography} from "@mui/material";
import {pass, payRansomForStreet, startRaid} from "../../utils/ws";

interface RaidModalProps {
    data: any;
    openModal: boolean;
    variant: number;
}

export default function RaidModal({data, openModal, variant}: RaidModalProps) {
    const [time, setTime] = useState<number>(10);
    const [open, setOpen] = useState<boolean>(false);
    const [sum, setSum] = useState<number>(10);

    useEffect(() => {
        if (openModal === false) {
            setOpen(false);
            setTime(10);
            setSum(10);
        }

        if (openModal) {
            setOpen(true);
        }
    }, [openModal]);

    useEffect(() => {
        if (time > 0 && openModal) {
            setTimeout(() => {
                setTime(time -1)
            }, 1000);
        }
    }, [time, openModal]);

    const getTitle = () => {
        if (variant === 1) {
            return 'Начать рейд улицы ?'
        }

        if (variant === 2) {
            return 'Откупиться от рейда ?'
        }
    }

    const information = () => {
        if (variant === 1) {
            return (
                <>
                    <Typography sx={{mt: 3, mb: 2}}>
                       Введите сумму для рейда
                    </Typography>
                    <TextField
                        sx={{ }}
                        value={sum}
                        type={"number"}
                        onChange={(e) =>
                            setSum(Number(e.target.value))
                        }
                        label={'Sum'}
                    />
                </>
            )
        }

        if (variant === 2 && data) {
            return (
                <>
                    <Typography sx={{mt: 2}}>
                        {`Опоненет рейдит вашу улицу №${data.street}`}
                    </Typography>
                    <Typography>
                        {`Вы можете откупиться от захвата улицы заплатив: ${data.sumRansom} zollar`}
                    </Typography>
                </>
            )
        }
    }

    const actions = () => {
        if (variant === 1) {
            return (
                <>
                    <Button sx={{mr: 2}} variant={'outlined'} onClick={() => setOpen(false)}>
                        Нет
                    </Button>
                    <Button variant={'contained'} onClick={() => {startRaid(data.streetIndex, sum); setOpen(false)}}>
                        Да
                    </Button>
                </>
            )
        }

        if (variant === 2) {
            return (
                <>
                    <Button sx={{mr: 2}} variant={'outlined'} onClick={() => {setOpen(false); pass()}}>
                        Нет
                    </Button>
                    <Button variant={'contained'} onClick={() => payRansomForStreet()}>
                        Да
                    </Button>
                </>
            )
        }
    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {getTitle()}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {variant === 2 ? `Время для принятия решения: ${time}` : null}
                    </DialogContentText>
                    {information()}
                </DialogContent>
                <DialogActions>
                    {actions()}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}