import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ProfileSchema} from "../types/ProfileSchema";

const initialState: ProfileSchema = {
    email: null,
    userID: null,
    dateRegistration: null,
    nickname: null,
    rating: null,
    error: null,
    isLoading: false,
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfileData: (state: ProfileSchema, action: PayloadAction<ProfileSchema>) => {
            state.email = action.payload.email;
            state.userID = action.payload.userID;
            state.dateRegistration = action.payload.dateRegistration;
            state.nickname = action.payload.nickname;
            state.rating = action.payload.rating;
        }
    },
});

export const { actions: profileActions } = profileSlice;
export const { reducer: profileReducer } = profileSlice;
