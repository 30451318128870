import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ReactNode, useState} from "react";
import Box from "@mui/material/Box";
import {chooseCharacter} from "../../utils/ws";

interface TaxiDialogProps {
    children: ReactNode;
    index: number;
    close: () => void;
}

export default function TaxiDialog(props: TaxiDialogProps) {
    const {children, index, close} = props;
    const [open, setOpen] = useState<boolean>(false);

    return (
        <React.Fragment>
            <Box sx={{cursor: 'pointer', width: '100%', height: '100%'}} onClick={() => setOpen(true)}>
                {children}
            </Box>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Выберите действие
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{width: '300px'}}>
                        Таксист переместит ваш жетон на одну клетку. Выберите направление.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size={'small'} sx={{mr: 2}} variant={'outlined'} onClick={() => setOpen(false)}>
                        Отмена
                    </Button>
                    <Button size={'small'} variant={'contained'} onClick={() => {chooseCharacter(index, -1); setOpen(false); close()}}>
                        Назад
                    </Button>
                    <Button size={'small'} variant={'contained'} onClick={() => {chooseCharacter(index, 1); setOpen(false); close()}}>
                        Вперёд
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}