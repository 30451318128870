import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {GameListSchema} from "../types/GameListSchema";

const initialState: GameListSchema = {
    gamesSession: [],
    isLoading: false,
    error: ''
}

export const gameListSlice = createSlice({
    name: 'gameList',
    initialState,
    reducers: {
        updateData: (state: GameListSchema, action: PayloadAction<GameListSchema>) => {
            state.gamesSession = action.payload.gamesSession
        }
    },
});

export const { actions: gameListActions } = gameListSlice;
export const { reducer: gameListReducer } = gameListSlice;
