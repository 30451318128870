import React, {memo, useCallback} from "react";
import {Box, Button, Container, CssBaseline, Grid, TextField, Typography} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {useTheme} from "../hooks/theme";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../hooks/redux";
import {useSelector} from "react-redux";
import {getSignupNickname} from "../config/store/selectors/signup/getSignupNickname";
import {getSignupEmail} from "../config/store/selectors/signup/getSignupEmail";
import {getSignupPassword} from "../config/store/selectors/signup/getSignupPassword";
import {getSignupIsLoading} from "../config/store/selectors/signup/getSignupIsLoading";
import {getSignupError} from "../config/store/selectors/signup/getSignupError";
import {signupActions} from "../config/store/slices/signupSlice";
import {signup} from "../service/signup";
import {Validator} from "../utils/utils";

const Signup = memo(() => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const email = useSelector(getSignupEmail);
    const nickname = useSelector(getSignupNickname);
    const password = useSelector(getSignupPassword);
    const isLoading = useSelector(getSignupIsLoading);
    const error = useSelector(getSignupError);
    const navigate = useNavigate();
    const [errPass, setErrPass] = React.useState<string | null>(null);
    const [errEmail, setErrEmail] = React.useState<string | null>(null);
    const [errNick, setErrNick] = React.useState<string | null>(null);


    const onChangeEmail = useCallback((value: string) => {
        dispatch(signupActions.setEmail(value));
    }, [dispatch]);

    const onChangeNickname = useCallback((value: string) => {
        dispatch(signupActions.setNickname(value));
    }, [dispatch]);

    const onChangePassword = useCallback((value: string) => {
        dispatch(signupActions.setPassword(value));
    }, [dispatch]);

    const onSignupClick = useCallback(async () => {
        const errorEmail = Validator.getErrorText(Validator.checkEmail(email))
        const errorPassword = Validator.getErrorText(Validator.checkPassword(password))
        const errorNickname = Validator.getErrorText(Validator.checkNickname(nickname))
        setErrEmail(errorEmail);
        setErrPass(errorPassword);
        setErrNick(errorNickname);

        if (errorEmail === null && errorPassword === null && errorNickname === null) {
            const result = await dispatch(signup({ email, nickname, password }))
            if (result.payload.msg === '') {
                navigate('/')
            }
        }

    },  [dispatch, password, nickname, email, navigate]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: {lg: 16, xs: 0},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{marginTop: {lg: 0, xs: 16}}} component="h1" variant="h5">
                    {t('SIGNUP')}
                </Typography>
                <Box component="form" noValidate sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                error={!!errEmail}
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                value={email}
                                onChange={(e) => onChangeEmail(e.target.value)}
                                autoComplete="email"
                                autoFocus
                                helperText={errEmail}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!errNick}
                                required
                                autoComplete="nickname"
                                name="nickname"
                                value={nickname}
                                onChange={(e) => onChangeNickname(e.target.value)}
                                fullWidth
                                id="nickname"
                                label="Nickname"
                                helperText={errNick}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!errPass}
                                required
                                fullWidth
                                value={password}
                                onChange={(e) => onChangePassword(e.target.value)}
                                name={t('PASSWORD')}
                                label={t('PASSWORD')}
                                type="password"
                                id="password"
                                autoComplete="password"
                                helperText={errPass}
                            />
                        </Grid>
                    </Grid>
                    {error ? <Typography color={'error'} sx={{mt: 1}}>{error}</Typography> : null}
                    <Button
                        id="signUpBtn"
                        type="button"
                        fullWidth
                        onClick={onSignupClick}
                        disabled={isLoading}
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        {t('SIGNUP')}
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Typography
                                component={Link} id={'signUpLink'}
                                to={'/login'}
                                sx={{textDecoration: 'none', color: theme.palette.primary.main}}
                            >
                                {t('LINK_TO_LOGIN')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>


            </Box>
        </Container>
    );
});

export default Signup;