import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {useEffect, useState} from "react";
import {Button, TextField, Typography} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import {doCasinoBet} from "../../utils/ws";
import {CasinoBet} from "../../types/types";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    bgcolor: '#65a300',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 6,
};

interface CasinoModalProps {
    open: boolean;
    timer: number;
    balance: number;
}

const grid = [
    {value: 10, visual: 10, color: '#181818', bgColor: '#b70000'},
    {value: 11, visual: 11, color: '#ffffff', bgColor: '#00ff00'},
    {value: 0, visual: <CachedIcon fontSize="large" />, color: '#ffffff', bgColor: '#0000ff'},
    {value: 7, visual: 7, color: '#ffffff', bgColor: '#181818'},
    {value: 8, visual: 8, color: '#181818', bgColor: '#b70000'},
    {value: 9, visual: 9, color: '#ffffff', bgColor: '#181818'},
    {value: 4, visual: 4, color: '#181818', bgColor: '#b70000'},
    {value: 5, visual: 5, color: '#ffffff', bgColor: '#181818'},
    {value: 6, visual: 6, color: '#181818', bgColor: '#b70000'},
    {value: 1, visual: 1, color: '#ffffff', bgColor: '#181818'},
    {value: 2, visual: 2, color: '#181818', bgColor: '#b70000'},
    {value: 3, visual: 3, color: '#ffffff', bgColor: '#181818'},
]

const redAndBlack = [
    {value: 12, visual: 'Чёрное', color: '#181818', bgColor: '#65a300'},
    {value: 13, visual: 'Красное', color: '#b70000', bgColor: '#65a300'},
]


export default function CasinoModal(props: CasinoModalProps) {
    const [time, setTime] = useState<number>(15);
    const {open, timer, balance} = props;
    const [bet, setBet] = useState<number>(10);
    const [selectedEl, setSelectedEl] = useState<number>();
    const [bets, setBets] = useState<Array<CasinoBet>>([]);


    useEffect(() => {
        if (open === false) {
            setTime(15);
            setBets([]);
            setSelectedEl(null);
        }

        if (time > 0 && open) {
            setTimeout(() => {
                setTime(time -1)
            }, 1000);
        }
    }, [time, open, timer]);

    const addBet = () => {
        setBets(prevState => [...prevState, {value: selectedEl, sum: bet}])
        doCasinoBet(selectedEl, bet)
    }

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography mb={2} mt={2} align={'center'} variant={'h4'}>
                        До завершения казино: {time}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItem: 'center',
                        justifyContent: 'center'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            maxWidth: '462px',
                        }}>
                            {grid.map((item) => (
                                <Box
                                    sx={{
                                        color: item.color,
                                        backgroundColor: item.bgColor,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '2px solid',
                                        borderColor: item.value === selectedEl ? '#ff12c1' : '#cccccc',
                                        width: '150px',
                                        height: '100px',
                                        fontSize: '38px',
                                        lineHeight: '1.25',
                                        fontFamily: 'Arial',
                                        cursor: 'pointer',
                                    }}
                                    key={item.value}
                                    onClick={() => {
                                        setSelectedEl(item.value)
                                    }}
                                >
                                    {item.visual}
                                </Box>
                            ))}
                        </Box>
                        <Box>
                            {redAndBlack.map((item) => (
                                <Box
                                    sx={{
                                        color: item.color,
                                        width: '50px',
                                        height: '204px',
                                        border: '2px solid',
                                        borderColor: item.value === selectedEl ? '#ff12c1' : '#cccccc',
                                        backgroundColor: item.bgColor,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        writingMode: 'vertical-rl',
                                        textOrientation: 'upright',
                                        fontSize: '25px',
                                        lineHeight: '1',
                                        fontFamily: 'Arial',
                                        cursor: 'pointer'
                                    }}
                                    key={item.value}
                                    onClick={() => {
                                        setSelectedEl(item.value)
                                    }}
                                >
                                    {item.visual}
                                </Box>
                            ))}
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', ml: 5, mb: 1}}>
                            <Typography>
                                Ваши ставки:
                            </Typography>
                            {bets?.map((item) => (
                                <Typography key={item.value}>
                                    {
                                        item.value === 13
                                            ? `Красное - ставка: ${item.sum}`
                                            : item.value === 12
                                                ? `Чёрное - ставка: ${item.sum}`
                                                : `${item.value} - ставка: ${item.sum}`
                                    }
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '700px',
                        mt: 4
                    }}>
                        <TextField
                            sx={{ mr: 2}}
                            value={bet}
                            type={"number"}
                            onChange={(e) =>
                                setBet(Number(e.target.value))
                            }
                            label={'bet'}
                        />
                        <Button
                            disabled={(balance - bet) < 0 && selectedEl === null && bet <= 0}
                            size={'large'}
                            sx={{ height: '100%'}}
                            variant={'contained'}
                            onClick={() => {
                                addBet();
                                setBet(10);
                            }}
                        >
                            Сделать ставку
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}