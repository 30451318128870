import React, {Suspense} from 'react';
import Layout from "./components/ui/Layout";

function App() {

  return (
      <div className="App">
        <Suspense fallback="">
          <Layout />
        </Suspense>
      </div>
  );
}

export default App;
