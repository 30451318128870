import {memo, useEffect, useRef, useState} from "react";
import {Box, Button, TextField, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {
    buildFactory,
    buildHotel,
    buyStreet, buyStreetNotary, choosePlayer,
    chooseQuarter, destroyBuilding,
    initWS,
    pass,
    sellIndustry,
    sendMsgToChat, startTrade
} from "../utils/ws";
import { ReactComponent as MapImg } from '../assets/img/result.svg'
import {initMapScript} from "../utils/scrollAndDrag";
import {socket} from "../utils/ws";
import {GameData, LastBetData, Player, Street, Table, Token} from "../types/types";
import {getPlayersNicknames, marks, toastOptions, toastOptionsLighting, toastOptionsNoClose} from "../utils/utils";
import userImg from "../assets/img/user.png";
import AuctionModal from "../components/ui/AuctionModal";
import CasinoModal from "../components/ui/CasinoModal";
import {toast} from "react-toastify";
import {theme} from "../config/theme/theme";
import SendIcon from '@mui/icons-material/Send';
import * as React from "react";
import HandModal from "../components/ui/HandModal";
import CustomDialog from "../components/ui/Dialog";
import TokensModal from "../components/ui/TokensModal";
import FactoryDialog from "../components/ui/FactoryDialog";
import CardImage from "../components/ui/CardImage";
import WorkerModal from "../components/ui/WorkerModal";
import ProtectDialog from "../components/ui/ProtectDialog";
import RaidModal from "../components/ui/RaidModal";
import BlackBrokerModal from "../components/ui/BlackBrokerModal";
import TradeModal from "../components/ui/TradeModal";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import IconButton from "@mui/material/IconButton";

const positions = [
    {position: -1},
    {position: -1},
    {position: -1},
    {position: -1},
    {position: -1},
    {position: -1},
]

const places = [
    {src: userImg, left: 25, top: 115},
    {src: userImg, left: 25, top: 390},
    {src: userImg, left: 25, top: 665},
    {src: userImg, right: 25, top: 115},
    {src: userImg, right: 25, top: 390},
    {src: userImg, right: 25, top: 665},
]

const points = [
    [], [], [], [], [], [],
    [], [], [], [], [], [],
    [], [], [], [], [], [],
    [], [], [], [], [], [],
]

const sections = [
    [0, 1, 2, 3],
    [4, 5, 6, 7],
    [8, 9, 10, 11],
    [12, 13, 14, 15],
    [16, 17, 18, 19],
    [20, 21, 22, 23]
]

const streetsOwners = [
    -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1,
]

const Room = memo(() => {
    const [data, setData] = useState<GameData>(null);
    const [players, setPlayers] = useState<Player[]>([]);
    const [table, setTable] = useState<Table>(null);
    const [streets, setStreets] = useState<Array<Street>>([]);
    const [tokens, setTokens] = useState<Array<Token>>([]);
    const [playerTokens, setPlayerTokens] = useState<Array<number>>([]);
    const [quarters, setQuarters] = useState<Array<number>>([]);
    const [disableListeners, setDisableListeners] = useState<boolean>(false);
    const playersNames = useRef<Array<string>>();


    const balance = useRef(0);
    const params = useParams();
    const roomParams = params.data;
    const maxPlayers = Number(roomParams[roomParams.indexOf('=', 8) + 1]);

    const playerData = useRef<Player>();
    const userIndex = useRef<number>();
    const quartersData = useRef<number[]>([]);
    const [userSection, setUserSection] = useState<Array<number>>([]);
    let timeout;

    //auction
    const [startAuction, setStartAuction] = useState(false);
    const [auctionData, setAuctionData] = useState(null);
    const [auctionTimer, setAuctionTimer] = useState<number>(15);
    const [lastBetData, setLastBetData] = useState<LastBetData>(null);

    //casino
    const [startCasino, setStartCasino] = useState(false);

    //chat
    const [chatMessages, setChatMessages] = useState<Array<string>>([]);
    const [chatInput, setChatInput] = useState('');

    // hand
    const hand = useRef<Array<number>>();

    //features
    const [abilityTrickster , setAbilityTrickster] = useState(false);
    const [abilityAssassin , setAbilityAssassin] = useState(false);
    const [quartersListener, setQuartersListener] = useState<boolean>(false);
    const [abilityNotary, setAbilityNotary] = useState<boolean>(false);
    let assassinTargets = [];
    const tempStreetArr = useRef<Array<Number>>([]);


    // broker
    const abilityBroker = useRef<boolean>(false);
    const [brokerTrade, setBrokerTrade] = useState<boolean>(false);
    const [targetTokens, setTargetTokens] = useState<number[]>([]);
    const [targetPlayer, setTargetPlayer] = useState(null);

    // Protect dialogs

    const [protectDialog, setProtectDialog] = useState(false);
    const [protectDialogData, setProtectDialogData] = useState<any>(null);
    const [protectDialogCase, setProtectDialogCase] = useState<number>(null);

    // Raid
    const [raidDialog, setRaidDialog] = useState(false);
    const [raidDialogData, setRaidDialogData] = useState<any>(null);
    const [raidDialogCase, setRaidDialogCase] = useState<number>(null);
    const [raidAbility, setRaidAbility] = useState<boolean>(true);

    //Trade
    const [tradeModal, setTradeModal] = useState<boolean>(false);
    const [tradeData, setTradeData] = useState<any>();
    const [reshuffle, setReshuffle] = useState<boolean>(false);
    const [accept, setAccept] = useState<boolean>(false);
    const initiator = useRef<boolean>(false);

    useEffect(() => {
        initMapScript();
    }, []);

    /* eslint-disable */
    useEffect(() => {
        if (roomParams) {
            initWS(`?${roomParams}`)
            socket.onmessage = receiveMsg;
        }
    }, [roomParams]);

    /* eslint-enable */

    function receiveMsg(event) {
        let response = JSON.parse(event.data);
        console.log('Получено сообщение', response)

        if (response.type === 'zollar-player') {
            if (response.message.indexPlayer !== undefined && response.message.indexPlayer === userIndex.current) {
                balance.current = response.message.zollarPlayer
            } else {
                balance.current = response.message;
            }
        }

        if (response.type === 'updating-data') {
            userIndex.current = response.message.indexPlayer
            console.log('userIndex', userIndex.current);
            setData(response.message);
            setTable(response.message.table);
            setStreets(response.message.table.streets);
            setPlayers(response.message.updatingPlayers);
            playersNames.current = getPlayersNicknames(response.message.updatingPlayers);
            playerData.current = response.message.updatingPlayers.find(el => el.indexPlayer === userIndex.current);
            setPlayerTokens(response.message.updatingPlayers[userIndex.current].marketTokens);
            setTokens(response.message.updatingMarketToken);
            setQuarters(response.message.table.quarters);
            quartersData.current = response.message.table.quarters;

            let lastPos = response.message.updatingPlayers.find(el => el.indexPlayer === userIndex.current).position;
            getUserSection(lastPos);

            setRaidAbility(true);
        }

        if (response.type === 'position-change') {
            movePlayers(response.message.indexPlayer, response.message.newPosition);
        }

        if (response.type === 'start-auction') {
            setStartAuction(true)
            setAuctionData(response.message)
        }

        if (response.type === 'stop-auction' && response.message.secondToStop === 2) {
            setTimeout(() => {
                setStartAuction(false);
                setAuctionData(null);
                setLastBetData(null);
                setAuctionTimer(15);
            }, 3000)
        }

        if (response.type === 'finish-auction' && response.message.playerWin === userIndex.current) {
            toast.success(`Вы выйграли ${response.message.prize > 5 ? 'отель' : 'улицу'}!`, toastOptions)
        }

        if (response.type === 'start-casino') {
            setStartCasino(true);
        }

        if (response.type === 'cheater-casino') {
            setProtectDialogCase(1)
            setProtectDialog(true);
            setProtectDialogData(response.message);
        }

        if (response.type === 'finish-casino') {
            setStartCasino(false);
            setProtectDialogCase(null)
            setProtectDialog(false);
            setProtectDialogData(null);
            clearPointsArr();
            response.message.yourBid.forEach((bet) => {
                casinoResult(response.message.winNumber, bet.value, response.message.color, bet.sum)
            })
        }

        if (response.type === 'player-bet-auction') {
            setLastBetData(response.message);
            setAuctionTimer(5);
        }

        if (response.type === 'information-message') {
            if (response.message.style === 1) toast.error(response.message.message, toastOptions);
            if (response.message.style === 2) toast(response.message.message, toastOptions);
            if (response.message.style === 3) toast.success(response.message.message, toastOptions);

            if (response.message.message.includes('thanks to the firefighter')) {
                setProtectDialog(false);
                setProtectDialogData(null);
                setProtectDialogCase(null);
                hand.current = hand.current.filter((number) => number !== 42)
            }
            if (response.message.message.includes('thanks to the doctor')) {
                setProtectDialog(false);
                setProtectDialogData(null);
                setProtectDialogCase(null);
                hand.current = hand.current.filter((number) => number !== 40)
                hand.current = hand.current.filter((number) => number !== 41)
            }

        }

        if (response.type === 'chat') {
            setChatMessages(prevState => [...prevState, response.message])
        }

        if (response.type === 'hand-player') {
            hand.current = response.message;
        }

        if (response.type === 'salary-change') {
            setPlayers(prevState =>
                prevState.map(player =>
                    player.indexPlayer === response.message.indexPlayer
                        ? {...player, salary: response.message.salaryPlayer}
                        : player
                )
            )
        }

        if (response.type === 'hire-worker') {
            setPlayers(prevState =>
                prevState.map(player =>
                    player.indexPlayer === response.message.player
                        ? player.worker1 === 0 ? {...player, worker1: response.message.numWorker}
                            : player.worker2 === 0 ? {...player, worker2: response.message.numWorker}
                                : player : player
                )
            )

            if (playerData.current.indexPlayer === response.message.player) {
                if  (playerData.current.worker1 === 0) {
                    playerData.current.worker1 = response.message.numWorker
                } else if (playerData.current.worker1 !== 0 && playerData.current.worker2 === 0) {
                    playerData.current.worker2 = response.message.numWorker
                }
                getUserSection(playerData.current.position)
            }

            if (userIndex.current === response.message.player) {
                toast.success('Вы наняли работника!', toastOptions);
                hand.current = hand.current.filter((number) => number !== response.message.numWorker)
            }
        }

        if (response.type === 'fire-worker') {
            setPlayers(prevState =>
                prevState.map(player =>
                    player.indexPlayer === response.message.player
                        ? player.worker1 === response.message.numWorker ? {...player, worker1: 0}
                            : player.worker2 === response.message.numWorker ? {...player, worker2: 0}
                                : player : player
                )
            )

            if (playerData.current.indexPlayer === response.message.player) {
                if  (playerData.current.worker1 === response.message.numWorker) {
                    playerData.current.worker1 = 0
                } else if (playerData.current.worker2 === response.message.numWorker) {
                    playerData.current.worker2 = 0
                }
                getUserSection(playerData.current.position)
            }

            if (userIndex.current === response.message.player) {
                toast.success('Вы уволили работника!', toastOptions);
            }
            setAbilityTrickster(false);
        }

        if (response.type === 'changing-state-street') {
            addOwnerToStreet(response.message.indexStreet, response.message.street.owner)
            setStreets(prevState =>
                prevState.map((street, index) =>
                    index === response.message.indexStreet
                        ? response.message.street : street
                )
            )
            removeLighting(1);
            removeLighting(2, response.message.street.industrial);
            toast.dismiss();
            clearTimeout(timeout);
            setAbilityNotary(false);
            removeLightForStreet();
        }

        if (response.type === 'hotels-refund') {
            setPlayers(prevState =>
                prevState.map(player =>
                    player.indexPlayer === userIndex.current
                        ? {...player, reserveHotel: response.message} : player
                )
            )
            playerData.current.reserveHotel = response.message
        }

        if (response.type === 'industrial-refund') {
            setPlayers(prevState =>
                prevState.map(player =>
                    player.indexPlayer === userIndex.current
                        ? {...player, reserveIndustries: response.message} : player
                )
            )
            playerData.current.reserveIndustries = response.message
        }

        if (response.type === 'market-token-change') {
            setPlayerTokens(prevState =>
                userIndex.current === response.message.indexPlayer ?
                prevState.map((item, index) =>
                    index === response.message.token
                        ? response.message.marketTokenPlayer : item

                ) : prevState
            )
            setPlayers(prevState =>
                prevState.map(player =>
                    player.indexPlayer === response.message.indexPlayer
                        ? {...player, marketTokens: player.marketTokens.map((item, index) =>
                                index === response.message.token ? response.message.marketTokenPlayer : item
                            )} : player
                )
            )
        }

        if (response.type === 'market-token-refund') {
            setTokens(prevState =>
                prevState.map((item, index) =>
                    index === response.message.indexToken
                        ? {...item, quantity: response.message.quantity} : item
                )
            )
        }

        if (response.type === 'player-pass') {
            toast(`Игрок под ником ${response.message.nickname} пасанул`, toastOptions)
        }

        if (response.type === 'choosing-quarters') {
            setDisableListeners(true);
            setQuartersListener(true);
            lightingQuarters(response.message.quarters);
            toast.info('Выберите квартал', toastOptionsNoClose)
        }

        if (response.type === 'hire-quarter') {
            setDisableListeners(false);
            setQuarters(prevState =>
                prevState.map((item, index) =>
                    index === response.message.quarter ? response.message.numWorker : item
                ))
            quartersData.current[response.message.quarter] = response.message.numWorker
            toast.dismiss();
            removeLightingQuarters();

            if (userIndex.current === response.message.player) {
                toast.success('Вы наняли квартального!', toastOptions);
                hand.current = hand.current.filter((number) => number !== response.message.numWorker)
            }
        }

        if (response.type === 'choosing-player') {
            lightingUser()
            toast.info('Выберите игрока', toastOptionsNoClose)
        }

        if (response.type === 'choosing-building') {
            lightingBuild(response.message.streets)
            toast.info('Выберите здание', toastOptionsNoClose)
        }

        if (response.type === 'influence-opponent') {
            removeLightingUser()
            toast.dismiss();
            if (userIndex.current === response.message.player) {
                toast.success('Вы использовали карту на игрока!', toastOptions);
                hand.current = hand.current.filter((number) => number !== response.message.numWorker)
            }
        }

        if (response.type === 'influence-yourself') {
            if (userIndex.current === response.message.player) {
                hand.current = hand.current.filter((number) => number !== response.message.numWorker)
            }
        }

        if (response.type === 'hand-refund') {
            hand.current = response.message
        }

        if (response.type === 'influence-building') {
            removeLightingBuild();
            toast.dismiss();
        }

        if (response.type === 'table-resources') {
            setTable(prevState => {
                let obj = Object.assign({}, prevState);
                obj.resources = response.message
                return obj
            })
        }


        if (response.type === 'table-factories') {
            setTable(prevState => {
                let obj = Object.assign({}, prevState);
                obj.factories = response.message
                return obj
            })
        }

        if (response.type === 'choosing-worker') {
            setAbilityTrickster(true);
        }

        if (response.type === 'choosing-target') {
            setAbilityAssassin(true);
        }

        if (response.type === 'influence-target-worker') {
            if (userIndex.current === response.message.player) {
                setAbilityAssassin(false);
                toast.success('Вы успешно применили карточку киллера!', toastOptions)
            }
        }

        if (response.type === 'block-quarter') {
            let index = quartersData.current.indexOf(response.message.numWorker)
            quartersData.current[index] = 0
            setQuarters(quartersData.current)
            hand.current = hand.current.filter((number, index) => index !== response.message.numHand)
        }

        if (response.type === 'use-firefighter') {
            setProtectDialogCase(2)
            setProtectDialog(true);
            setProtectDialogData(response.message);
        }

        if (response.type === 'use-doctor') {
            setProtectDialogCase(3)
            setProtectDialog(true);
            setProtectDialogData(response.message);
        }

        if (response.type === 'selection-street') {
            setAbilityNotary(true);
            tempStreetArr.current = response.message.streets;
            lightForStreetCard(response.message.streets)
        }

        if (response.type === 'influence-target-renegade') {
            if (userIndex.current === response.message.player) {
                hand.current = hand.current.filter((number) => number !== 40)
            }
            setQuarters(prevState =>
                prevState.map((item) =>
                    item === response.message.numWorker ? 0 : item
                ))
        }

        if (response.type === 'doctor-used') {
            setProtectDialogCase(null);
            setProtectDialog(null);
            setProtectDialogData(null);
        }

        if (response.type === 'firefighter-used') {
            setProtectDialogCase(null);
            setProtectDialog(null);
            setProtectDialogData(null);
        }

        if (response.type === 'street-capture') {
            if (response.message.ownerStreet === userIndex.current) {
                if (balance.current < response.message.sumRansom) {
                    pass();
                    return;
                }
                setRaidDialog(false);
                setRaidDialog(true);
                setRaidDialogData(response.message)
                setRaidDialogCase(2)
            } else {
                toast.info(`Идёт захват улицы №${response.message.street}. Все действия приостановлены`, toastOptionsLighting)
            }
            if (response.message.playerCapture === userIndex.current) {
                setRaidAbility(false)
            }
        }

        if (response.type === 'finish-capture') {
            setRaidDialog(false);
            setRaidDialogData(null);
            setRaidDialogCase(null);
            toast.dismiss();
        }

        if (response.type === 'trade-product') {
            setTradeData(response.message)
            setTradeModal(true);
            initiator.current = true;
        }

        if (response.type === 'trade-offer') {
            setTradeData(response.message)
            setReshuffle(!initiator.current);
            setTradeModal(true);
            setAccept(true);
        }

        if (response.type === 'choosing-player-token') {
            abilityBroker.current = true;
            lightingUserBroker(response.message.players);
        }

        if (response.type === 'broker-trade-cancel') {
            abilityBroker.current = false;
            removeLightingUser();
            setBrokerTrade(false);
        }
    }

    function movePlayers(indexPlayer: number, newPosition: number) {
        positions[indexPlayer].position = newPosition;
        points[newPosition].push(0)
        let lastPos = document.querySelector(`.lastPosition-${indexPlayer}`);
        if (lastPos) {
            lastPos.remove();
        }

        let el = document.getElementById(`point-${newPosition}`);
        let pos = el.getBoundingClientRect();

        let div = document.createElement('div');
        div.classList.add('mark', `lastPosition-${indexPlayer}`)
        div.style.background = marks[indexPlayer];


        createElPosition(div, pos, newPosition);
        document.getElementById('zoom').append(div);
    }

    const createElPosition = (div: HTMLDivElement, pos: DOMRect, point: number) => {
        let zoom = document.getElementById('zoom');
        let countOfPlayers = points[point].length;
        let top = 0;
        let left = 0;
        let adjustment = point > 2 && point !== 4

        switch (countOfPlayers) {
            case 1 :
                top = -15
                left = 20
                break;
            case 2 :
                top = -15
                left = 0
                break;
            case 3 :
                top = -15
                left = -20
                break;
            case 4 :
                top = 5
                left = 20
                break;
            case 5 :
                top = 5
                left = 0
                break;
            case 6 :
                top = 5
                left = -20
                break;
        }

        div.style.top = String(pos?.top - ((window.innerHeight - zoom.offsetHeight) / 1.6) + pos?.height / 2 + top) + 'px';
        div.style.left = String(pos?.left - ((window.innerWidth - zoom.offsetWidth) / (adjustment ? 2.22 : 2.15)) - pos?.width / 2 - left) + 'px';
    }

    const clearPointsArr = () => {
        points.forEach((point) => {
            point.length = 0;
        })
    }

    const casinoResult = (winNumber: number, betNumber: number, color: string, bet: number) => {
        if (winNumber === betNumber) {
            toast.success(`Выпало ${winNumber} ${color}. Вы выйграли ${bet * 11} zollar!`, toastOptions)
        } else if (color === 'black' && betNumber === 12) {
            toast.success(`Выпало ${winNumber} ${color}. Вы выйграли ${bet * 2} zollar!`, toastOptions)
        } else if (color === 'red' && betNumber === 13) {
            toast.success(`Выпало ${winNumber} ${color}. Вы выйграли ${bet * 2} zollar!`, toastOptions)
        } else {
            toast(`Выпало ${winNumber} ${color}. Ваша ставка проиграла`, toastOptions)
        }
    }

    const addOwnerToStreet = (index: number, owner: number) => {
        streetsOwners[index] = owner
        document.getElementById(`street-${index}`).style.fill = marks[owner]
    }

    const isStreetCanBeBought = (index: number) => {
        if (index === 3 || index === 7 || index === 11 || index === 15 || index === 19 || index === 23) {
            //toast('Эту улицу можно выйграть на аукционе', toastOptions);
            return;
        }

        if (abilityNotary && tempStreetArr.current.includes(index)) {
            buyStreetNotary(index);
        }

        if (!userSection.includes(index)) {
            //toast('Вы можете купить улицу только на том квартале где вы находитесь', toastOptions);
            return;
        }

        if (streetsOwners[index] !== userIndex.current && streetsOwners[index] !== -1 && raidAbility) {
            setRaidDialog(false);
            setRaidDialog(true);
            setRaidDialogCase(1);
            setRaidDialogData({streetIndex: index})
            return;
        }

        if (disableListeners) {
            return;
        }

        buyStreet(index);
    }

    const addCursorStreet = (ev, index) => {
        if (userSection.includes(index)) {
            ev.target.classList.add('cursor');
        }
    }

    const removeCursorStreet = (ev) => {
        ev.target.classList.remove('cursor');
    }

    const getUserSection = (pos: number) => {
        for (let i = 0; i < sections.length; i++) {
            if (sections[i].includes(pos)) {
                setUserSection(sections[i])
                if (playerData.current.worker1 === 9 || playerData.current.worker2 === 9) {
                    let arr = sections[i].concat(sections[i === 0 ? 5 : i - 1], sections[i === 5 ? 0 : i + 1])
                    setUserSection(arr)
                }
            }
        }
    }

    const lightingStreet = (funcCase: number, factoryId?: number) => {
        let matches = 0;
        if (funcCase === 1) {
            for (let i = 0; i < streetsOwners.length; i++) {
                if (streetsOwners[i] === userIndex.current && streets[i].hotel === -1) {
                    let el = document.getElementById(`hotelCard-${i}`)
                    el?.classList.add('lightForCard');
                    el?.addEventListener('click', () => {
                            buildHotel(i)
                    });
                    matches++
                }
            }
        }

        if (funcCase === 2) {
            for (let i = 0; i < streetsOwners.length; i++) {
                if (streetsOwners[i] === userIndex.current && streets[i].industrial === -1) {
                    let el = document.getElementById(`factoryCard-${i}`)
                    el?.classList.add('lightForCard');
                    el?.addEventListener('click', () => {
                        buildFactory(i, factoryId)
                    });
                    matches++
                }
            }
        }

        if (matches > 0) {
            if (funcCase === 1) {
                toast('Выберите улицу для постройки отеля', toastOptionsLighting);
            }
            if (funcCase === 2) {
                toast('Выберите улицу для постройки завода', toastOptionsLighting);
            }
            timeout = setTimeout(() => {
                removeLighting(funcCase, factoryId);
            }, 10000);
        }
    }

    const removeLighting = (funcCase: number, factoryId?: number) => {
        for (let i = 0; i < streetsOwners.length; i++) {
            if (streetsOwners[i] === userIndex.current) {
                let el = document.getElementById(`${funcCase === 1 ? 'hotel' : 'factory'}Card-${i}`)
                el?.classList.remove('lightForCard');
                if (funcCase === 1) {
                    el?.removeEventListener('click', () => buildHotel(i));
                }
                if (funcCase === 2) {
                    el?.removeEventListener('click', () => buildFactory(i, factoryId));
                }
            }
        }
    }

    const onClickQuarter = (quarter: number) => {
        if (quartersListener) {
            chooseQuarter(quarter)
            setQuartersListener(false);
        }
    };


    const lightingQuarters = (quatersArr: number[]) => {
       for (let i = 0; i < quatersArr.length; i++) {
           let el = document.getElementById(`quarter-${quatersArr[i]}`);
           el.classList.add('lightForCard');
       }
    }

    const removeLightingQuarters = () => {
        for (let i = 0; i < 6; i++) {
            let el = document.getElementById(`quarter-${i}`)
            el.classList.remove('lightForCard');
        }
    }

    const lightingUser = () => {
        for (let i = 0; i < maxPlayers; i++) {
            if (userIndex.current !== i) {
                let el = document.getElementById(`user-${i}`);
                el.classList.add('lightingForUser');
                el.addEventListener('click', () => choosePlayer(i))
            }
        }
    }

    const lightingUserBroker = (obj: any) => {
        let targets = Object.keys(obj);
        targets.forEach((target) => {
            let el = document.getElementById(`user-${target}`);
            el.classList.add('lightingForUser');
            el.addEventListener('click', () => openBrokerTrade(target, obj[target].tokens))
        })
    }

    const openBrokerTrade = (indexPlayer: string, arr: number[]) => {
        if (abilityBroker.current) {
            console.log('work');
            setBrokerTrade(true);
            setTargetTokens(arr);
            setTargetPlayer(Number(indexPlayer))
        }
    }

    const lightingBuild = (streetsArr: any) => {
        for (let i = 0; i < streetsArr.length; i++) {
            if (streetsOwners[i] !== userIndex.current && streetsArr[i].industries !== -1) {
                let el = document.getElementById(`factoryCard-${i}`)
                el?.classList.add('lightForCard');
                el?.addEventListener('click', () => {
                    destroyBuilding(streetsArr[i].industries, 2)
                });
            }
            if (streetsOwners[i] !== userIndex.current && streetsArr[i].hotel !== -1) {
                let el = document.getElementById(`hotelCard-${i}`)
                el?.classList.add('lightForCard');
                el?.addEventListener('click', () => {
                    destroyBuilding(streetsArr[i].hotel, 1)
                });
            }
        }
    }

    const removeLightingBuild = () => {
        for (let i = 0; i < streetsOwners.length; i++) {
            if (streetsOwners[i] !== userIndex.current) {
                let el = document.getElementById(`factoryCard-${i}`)
                el?.classList.remove('lightForCard');
                el?.removeEventListener('click', () => {
                    destroyBuilding(streets[i].industrial, 2)
                });
            }
            if (streetsOwners[i] !== userIndex.current) {
                let el = document.getElementById(`hotelCard-${i}`)
                el?.classList.remove('lightForCard');
                el?.removeEventListener('click', () => {
                    destroyBuilding(streets[i].hotel, 1)
                });
            }
        }
    }


    const removeLightingUser = () => {
        for (let i = 0; i < maxPlayers; i++) {
            let el = document.getElementById(`user-${i}`)
            el.classList.remove('lightingForUser');
            el.removeEventListener('click', () => choosePlayer(i))
        }
    }

    const lightForStreetCard = (arr: number[]) => {
        arr.forEach((item) => {
            const el = document.getElementById(`streetCard-${item}`);
            el.classList.add('lightForCard');
        })
    }

    const removeLightForStreet = () => {
        tempStreetArr.current.forEach((item) => {
            const el = document.getElementById(`streetCard-${item}`);
            el.classList.remove('lightForCard');
        })
        tempStreetArr.current = [];
    }


    const sellFactory = (streetIndex: number) => {
        if (streetsOwners[streetIndex] === userIndex.current) {
            sellIndustry(streetIndex, 1);
        }
    }

    const reserveFactory = (streetIndex: number) => {
        if (streetsOwners[streetIndex] === userIndex.current) {
            sellIndustry(streetIndex, 2);
        }
    }

    const collectStreets = () => {
        let arr: number[] = [];
        streets.forEach((item, index) => {
            if (item.owner === userIndex.current) {
                arr.push(index)
            }
        })
        return arr;
    }

    const handleCloseTrade = () => {
        setTradeModal(false);
        setAccept(false);
    }

    const getMapGrid = (quarter: number) => {

        return (
            streets?.map((item, index) => (
                sections[quarter].includes(index) ?
                    <Box
                        onMouseOver={(ev) => addCursorStreet(ev, index)}
                        onMouseOut={(ev) => removeCursorStreet(ev)}
                        onClick={() => isStreetCanBeBought(index)}
                        className={'interactiveStreet'}
                        id={`streetCard-${index}`}
                        key={index}
                    >
                        <Box id={`hotelCard-${index}`} className={'hotel-card'}>
                            {item.hotel > -1 ? <CardImage deck={`deck-lots/${item.hotel}`} alt={'hotelCard'}/> : null}
                        </Box>
                        <Box id={`factoryCard-${index}`} className={'factories-card'}>
                            {item.industrial > -1 ?
                                <FactoryDialog
                                    disabled={userIndex.current !== streetsOwners[index]}
                                    func={() => sellFactory(index)}
                                    func2={() => reserveFactory(index)}
                                    text={'* Продажа и отправка здания в резерв стоит половину от стоимости здания'}
                                    actionText={'Сохранить в резерве'}
                                >
                                    <CardImage deck={`deck-industry/${item.industrial}`} alt={'factoryCard'}/>
                                </FactoryDialog>
                                : null
                            }
                        </Box>
                    </Box>
                    : null
            ))
        )
    }

    return (
        <Box sx={{
            background: '#E2D6CE',
            width: '100%',
            height: 895,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Box sx={{
                width: '70%',
                height: 700,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
            }}>
                <Box id={"zoom"}>
                    <MapImg id={'svg'}/>

                    {/*mapGrid*/}

                    <Box id={'mapGrid'} sx={{position: 'absolute', top: 0, left: 0, width: '100%', height: '700px'}}>
                        <Box className={"interactiveBlock"}>
                            <Box id={'quarter-1'} className={"quarter"} onClick={() => onClickQuarter(1)}>
                                {getMapGrid(1)}
                                <Box className={'quarterCard'}>
                                    {quarters[1] > 0 ?
                                        <WorkerModal
                                            worker={quarters[1]}
                                            isUserWorker={assassinTargets.includes(quarters[1])}
                                            indexPlayer={1}
                                            assassin={abilityAssassin}
                                        />
                                        : null
                                    }
                                </Box>
                            </Box>
                            <Box id={'quarter-2'} className={"quarter"} onClick={() => onClickQuarter(2)}>
                                {getMapGrid(2)}
                                <Box className={'quarterCard'}>
                                    {quarters[2] > 0 ?
                                        <WorkerModal
                                            worker={quarters[2]}
                                            isUserWorker={assassinTargets.includes(quarters[2])}
                                            indexPlayer={2}
                                            assassin={abilityAssassin}
                                        />
                                        : null
                                    }
                                </Box>
                            </Box>
                            <Box id={'quarter-3'} className={"quarter"} onClick={() => onClickQuarter(3)}>
                                {getMapGrid(3)}
                                <Box className={'quarterCard'}>
                                    {quarters[3] > 0 ?
                                        <WorkerModal
                                            worker={quarters[3]}
                                            isUserWorker={assassinTargets.includes(quarters[3])}
                                            indexPlayer={3}
                                            assassin={abilityAssassin}
                                        />
                                        : null
                                    }
                                </Box>
                            </Box>
                            <Box id={'quarter-4'} className={"quarter"} onClick={() => onClickQuarter(4)}>
                                {getMapGrid(4)}
                                <Box className={'quarterCard'}>
                                    {quarters[4] > 0 ?
                                        <WorkerModal
                                            worker={quarters[4]}
                                            isUserWorker={assassinTargets.includes(quarters[4])}
                                            indexPlayer={4}
                                            assassin={abilityAssassin}
                                        />
                                        : null
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <Box className={"interactiveBlock2"}>
                            <Box id={'quarter-0'} className={'quarter'} sx={{m: '77px 0px 0px 48px', flexDirection: 'row-reverse'}} onClick={() => onClickQuarter(0)}>
                                {getMapGrid(0)}
                                <Box className={'quarterCard'}>
                                    {quarters[0] > 0 ?
                                        <WorkerModal
                                            worker={quarters[0]}
                                            isUserWorker={assassinTargets.includes(quarters[0])}
                                            indexPlayer={0}
                                            assassin={abilityAssassin}
                                        />
                                        : null
                                    }
                                </Box>
                            </Box>
                            <Box className={"cardsBlock"}>
                                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                    <Box sx={{display: 'flex', width: 'auto', height: '81px', m: '12px 0px 0px 126px'}}>
                                        <Box className={'purpleCard'}></Box>
                                        <Box className={'purpleCard'}></Box>
                                        <Box className={'purpleCard'}></Box>
                                    </Box>
                                    <Box sx={{display: 'flex', width: 'auto', height: '116px', m: '13px 0px 0px 5px'}}>
                                        {table?.factories.map((item) => (
                                            <Box key={item} className={'yellow-card'}>
                                                <CustomDialog func={() => lightingStreet(2, item)}
                                                              title={'Построить завод ?'}
                                                              text={'* У вас должна быть улица в собственности для строительства'}>
                                                    <img
                                                        width={'100%'}
                                                        height={'100%'}
                                                        src={`https://static.farm.thunderstrike.studio/get-rich/deck-industry/${item}.png`}
                                                        srcSet={`https://static.farm.thunderstrike.studio/get-rich/deck-industry/${item}.png`}
                                                        alt={'industryCard'}
                                                    />
                                                </CustomDialog>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box sx={{display: 'flex', width: 'auto', height: '116px', m: '6px 0px 0px 5px'}}>
                                        {table?.resources.map((item) => (
                                            <Box key={item} className={'yellow-card'}>
                                                <CustomDialog func={() => lightingStreet(2, item)}
                                                              title={'Построить завод ?'}
                                                              text={'* У вас должна быть улица в собственности для строительства'}>
                                                    <img
                                                        width={'100%'}
                                                        height={'100%'}
                                                        src={`https://static.farm.thunderstrike.studio/get-rich/deck-industry/${item}.png`}
                                                        srcSet={`https://static.farm.thunderstrike.studio/get-rich/deck-industry/${item}.png`}
                                                        alt={'industryCard'}
                                                    />
                                                </CustomDialog>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', width: '116px', height: 'auto', margin: '13px 0px 0px 0px'}}>
                                    <Box className={"purpleCard2"}></Box>
                                    <Box className={"purpleCard2"}></Box>
                                    <Box className={"purpleCard2"}></Box>
                                    <Box className={"purpleCard2"}></Box>
                                </Box>
                            </Box>
                            <Box id={'quarter-5'} className={'quarter'} sx={{mt: '77px', flexDirection: 'row-reverse'}} onClick={() => onClickQuarter(5)}>
                                {getMapGrid(5)}
                                <Box className={'quarterCard'}>
                                    {quarters[5] > 0 ?
                                        <WorkerModal
                                            worker={quarters[5]}
                                            isUserWorker={assassinTargets.includes(quarters[5])}
                                            indexPlayer={5}
                                            assassin={abilityAssassin}
                                        />
                                        : null
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/*places*/}

            {players.map((player, index) => (
                index++ < maxPlayers ?
                    <Box key={player.indexPlayer}
                         className={'user-place'}
                         sx={{
                             left: player.indexPlayer <= 2 ? places[player.indexPlayer].left : places[player.indexPlayer].right,
                             top: places[player.indexPlayer].top
                         }}
                    >
                        <Box sx={{
                            width: '34%',
                            flexBasis: '34%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <WorkerModal
                                isUserWorker={player.indexPlayer === userIndex.current}
                                place={1}
                                worker={player.worker1}
                                indexPlayer={player.indexPlayer}
                                trickster={abilityTrickster}
                                assassin={abilityAssassin}
                            />
                            <WorkerModal
                                isUserWorker={player.indexPlayer === userIndex.current}
                                place={2}
                                worker={player.worker2}
                                indexPlayer={player.indexPlayer}
                                trickster={abilityTrickster}
                                assassin={abilityAssassin}
                            />
                        </Box>
                        <Box sx={{
                            flexWrap: 'nowrap',
                            whiteSpace: 'nowrap',
                            width: '40%',
                            flexBasis: '40%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',

                        }}>
                            <img
                                width={'50px'}
                                height={'50px'}
                                id={`user-${player.indexPlayer}`}
                                src={places[player.indexPlayer].src}
                                srcSet={places[player.indexPlayer].src}
                                alt={'user place img'}
                            />
                            <Typography fontSize={'1rem'}>
                                {player.nickname}
                            </Typography>
                            <Typography fontSize={'0.9rem'}>
                                Salary: {player.salary}
                            </Typography>
                            <Typography fontSize={'0.9rem'}>
                                {data.indexPlayer === player.indexPlayer ? `Balance: ${balance.current}` : null}
                            </Typography>
                            <Typography fontSize={'0.9rem'}>
                                {player.reserveHotel.length ? `Отели: ${player.reserveHotel.length}` : null}
                            </Typography>
                        </Box>
                        <Box sx={{width: '24%', flexBasis: '24%'}}>
                            {
                                data.indexPlayer === player.indexPlayer
                                    ? <HandModal factorysReserv={player.reserveIndustries} cards={hand} hotelReserv={player.reserveHotel} lightFunc={lightingStreet}/>
                                    : <IconButton onClick={() => startTrade(player.indexPlayer)}><CurrencyExchangeIcon fontSize={'medium'} /></IconButton>
                            }
                        </Box>
                        <Box sx={{
                            width: '100%',
                            flexBasis: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            display: 'flex'
                        }}>
                            {player.marketTokens.map((token, index) => (
                                <Box key={index} sx={{display: 'flex', width: '15%', alignItems: 'center'}}>
                                    <Typography mr={1}>{token}</Typography>
                                    <img
                                        width={'20px'}
                                        height={'20px'}
                                        src={`https://static.farm.thunderstrike.studio/get-rich/tokens/${index}.png`}
                                        srcSet={`https://static.farm.thunderstrike.studio/get-rich/tokens/${index}.png`}
                                        alt={'token'}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    : null
            ))}

            <Box sx={{position: 'absolute', top: 30, left: 200}}>
                {
                    playerData.current?.reserveIndustries.length || playerData.current?.reserveHotel.length
                        ? <CustomDialog func={() => pass()} title={'У вас остались здания в резерве'}
                                        text={'Если вы закончите ход заводы будут проданы за половину стоимости, а отели исчезнут! Закончить ход ?'}>
                            <Button variant={'contained'}>
                                Pass
                            </Button>
                        </CustomDialog>
                        : <Button onClick={() => pass()} variant={'contained'}>
                            Pass
                        </Button>
                }
            </Box>

            <Box sx={{position: 'absolute', top: 30, left: 300}}>
                <TokensModal data={tokens} playerTokensData={playerTokens}/>
            </Box>
            {/*chat*/}

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '250px',
                height: '400px',
                position: 'absolute',
                right: 30,
                bottom: 100
            }}>
                <Typography
                    variant={'h6'}
                    align={'center'}
                    sx={{width: '100%', backgroundColor: theme.palette.primary.main, pt: 0.5, pb: 0.5}}
                >
                    Chat
                </Typography>
                <Box sx={{
                    width: '100%',
                    height: '285px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    alignItems: 'start',
                    justifyContent: 'start',
                    color: 'black',
                    overflowY: 'scroll',
                }}>
                    {chatMessages.map((chatMsg) => (
                        <Typography key={chatMsg} sx={{fontSize: '0.9rem', m: 1}}>{chatMsg}</Typography>
                    ))}
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                    alignItems: 'center',
                    pl: 0.5,
                    pr: 0.5,
                }}>
                    <TextField
                        variant={"outlined"}
                        value={chatInput}
                        onChange={(e) =>
                            setChatInput(e.target.value)
                        }
                        type={"text"}
                        label={'Type a message'}
                    />
                    <Button onClick={() => sendMsgToChat(chatInput)}
                            sx={{width: '20%', height: '30px', alignItems: 'center'}}>
                        <SendIcon/>
                    </Button>
                </Box>
            </Box>
            <ProtectDialog variant={protectDialogCase} openModal={protectDialog} data={protectDialogData}/>
            <AuctionModal userIndex={userIndex.current} open={startAuction} timer={auctionTimer} data={auctionData} lastBet={lastBetData}/>
            <CasinoModal balance={balance.current} open={startCasino} timer={15}/>
            <RaidModal data={raidDialogData} openModal={raidDialog} variant={raidDialogCase}/>
            <BlackBrokerModal
                openBroker={brokerTrade}
                balance={balance.current}
                tokens={targetTokens}
                indexPlayer={userIndex.current}
                data={tokens}
                indexOpponent={targetPlayer}
                nicknames={playersNames.current}
            />
            <TradeModal
                openModal={tradeModal}
                message={tradeData?.message}
                sellerItemsData={
                    {
                        handCharacters: hand.current,
                        industries: playerData?.current?.reserveIndustries,
                        streets: collectStreets(),
                        tokens: playerTokens,
                        zollar: balance.current
                    }
                }
                sellerOfferData={tradeData?.tradeOffer.exchange}
                buyerItemsData={tradeData?.tradeProduct}
                buyerOfferData={tradeData?.tradeOffer.target}
                close={handleCloseTrade}
                accept={accept}
                players={playersNames?.current}
                playerIndex={userIndex?.current}
                reshuffle={reshuffle}
            />
        </Box>
    );
});

export default Room;