import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Button} from "@mui/material";
import {assassinAction, fireWorker, tricksterAction} from "../../utils/ws";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end'
};

interface WorkerModalProps {
    worker: number;
    isUserWorker: boolean;
    indexPlayer: number;
    place?: number;
    trickster?: boolean;
    assassin?: boolean;
}

export default function WorkerModal(props: WorkerModalProps) {
    const {worker, place, isUserWorker, assassin, indexPlayer, trickster} = props;
    const [open, setOpen] = React.useState(false)


    function handleOpen() {
        if  (worker > 0) {
            setOpen(true);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Box sx={{cursor: 'pointer', border: '1px solid #1F2D3D', width: '40px', height: '60px'}} onClick={handleOpen}>
                {worker > 0 ?
                    <img
                        width={'40px'}
                        height={'60px'}
                        src={`https://static.farm.thunderstrike.studio/get-rich/deck-characters/${worker}.png`}
                        srcSet={`https://static.farm.thunderstrike.studio/get-rich/deck-characters/${worker}.png`}
                        alt={'worker'}
                    />
                    : null
                }
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img
                        width={'100%'}
                        height={'100%'}
                        src={`https://static.farm.thunderstrike.studio/get-rich/deck-characters/${worker}.png`}
                        srcSet={`https://static.farm.thunderstrike.studio/get-rich/deck-characters/${worker}.png`}
                        alt={'worker'}
                    />
                    {
                        isUserWorker ?
                            <Button variant={'contained'} onClick={() => {
                                fireWorker(place);
                                handleClose();
                            }} sx={{mt: 2}}>
                                Уволить
                            </Button>
                            : null
                    }
                    {
                        trickster && !isUserWorker ?
                            <Button variant={'contained'} onClick={() => {
                                tricksterAction(worker, indexPlayer);
                                handleClose();
                            }} sx={{mt: 2}}>
                                Сбросить
                            </Button>
                            : null
                    }
                    {
                        assassin && !isUserWorker ?
                            <Button variant={'contained'} onClick={() => {
                                assassinAction(worker, indexPlayer);
                                handleClose();
                            }} sx={{mt: 2}}>
                                Убить
                            </Button>
                            : null
                    }
                </Box>
            </Modal>
        </div>
    );
}