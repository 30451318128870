import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import cardImg from "../../assets/img/backOfCard.png";
import {chooseCharacter, sellIndustryFromReserve} from "../../utils/ws";
import {Typography} from "@mui/material";
import CustomDialog from "./Dialog";
import FactoryDialog from "./FactoryDialog";
import CardImage from "./CardImage";
import TaxiDialog from "./TaxiDiaolog";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1300,
    height: 800,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    alignItems: 'center',
    overflow: 'scroll',
    flexWrap: 'wrap'
};

interface HandModalProps {
    cards: React.MutableRefObject<number[]>;
    hotelReserv: Array<number>;
    factorysReserv: Array<number>;
    lightFunc: (funcCase: number, additionalProp?: number) => void;
}

export default function HandModal({cards, hotelReserv, factorysReserv, lightFunc}: HandModalProps) {
    const [open, setOpen] = React.useState(false);
    const [hand, setHand] = React.useState([]);
    const [hotels, setHotels] = React.useState([]);
    const [factorys, setFactorys] = React.useState([]);

    function handleOpen() {
        setHand(cards.current)
        setHotels(hotelReserv)
        setFactorys(factorysReserv)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const buildHotelFromReserv = () => {
        setOpen(false);
        lightFunc(1);
    }

    const buildFactoryFromReserv = (factoryId: number) => {
        setOpen(false);
        lightFunc(2, factoryId);
    }

    const getCard = (card: number, index: number) => {

        if (card === 35) {
            return (
                <Box sx={{m: 2, height: '250px', width: '175px', cursor: 'pointer', border: '1px solid black'}}
                     key={card}
                >
                    <TaxiDialog close={handleClose} index={index}>
                        <CardImage deck={`deck-characters/${card}`} alt={'characterCard'}/>
                        {card}
                    </TaxiDialog>

                </Box>
            )
        }

        return (
            <Box onClick={() => {
                chooseCharacter(index);
                handleClose();
            }}
                 sx={{m: 2, height: '250px', width: '175px', cursor: 'pointer', border: '1px solid black'}}
                 key={card}
            >
                <CardImage deck={`deck-characters/${card}`} alt={'characterCard'}/>
                {card}
            </Box>
            )
    }

    return (
        <div>
            <Box sx={{cursor: 'pointer'}} onClick={handleOpen}>
                <img
                    width={'50px'}
                    height={'70px'}
                    src={cardImg}
                    srcSet={cardImg}
                    alt={'user hand'}
                />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant={'h4'} sx={{m: 1, flexBasis: '100%', justifyContent: 'center', display: 'flex'}}>
                        Your cards
                    </Typography>
                    <Box sx={{width: '95%', border: '1px solid #eee', height: '1px', backgroundColor: '#eee'}} />
                    {hand.map((card, index) => (
                        getCard(card, index)
                    ))}
                    {hotels.length ?
                        <>
                            <Typography variant={'h4'} sx={{m: 2, flexBasis: '100%', justifyContent: 'center', display: 'flex'}}>
                                Reserve hotel
                            </Typography>
                            <Box sx={{
                                width: '95%',
                                border: '1px solid #eee',
                                height: '1px',
                                backgroundColor: '#eee'
                            }}/>
                            {hotels?.map((hotel, index) => (
                                <Box sx={{m: 1, height: '250px', width: '175px', cursor: 'pointer'}} key={hotel}>
                                    <CustomDialog
                                        text={'Выбрать улицу для постройки отеля ?'}
                                        title={'Подтвердите действие'}
                                        func={buildHotelFromReserv}
                                    >
                                        <CardImage deck={`deck-lots/${hotel}`} alt={'hotelCard'}/>
                                    </CustomDialog>
                                </Box>
                            ))}
                        </>
                        : null
                    }
                    {factorys.length ?
                        <>
                            <Typography variant={'h4'} sx={{m: 1, flexBasis: '100%', justifyContent: 'center', display: 'flex'}}>
                                Reserve factory
                            </Typography>
                            <Box sx={{
                                width: '95%',
                                border: '1px solid #eee',
                                height: '1px',
                                backgroundColor: '#eee'
                            }}/>
                            {factorys?.map((factory, index) => (
                                <Box sx={{m: 2, height: '250px', width: '175px', cursor: 'pointer'}} key={factory}>
                                    <FactoryDialog
                                        text={'* Продажа принесёт прибыль в размере половины от стоимости здания!'}
                                        actionText={'Построить'}
                                        func={() => {
                                            sellIndustryFromReserve(index);
                                            handleClose();
                                        }}
                                        func2={() => buildFactoryFromReserv(factory)}
                                        disabled={false}
                                    >
                                        <CardImage deck={`deck-industry/${factory}`} alt={'factoryCard'}/>
                                    </FactoryDialog>
                                </Box>
                            ))}
                        </>
                        : null
                    }
                </Box>
            </Modal>
        </div>
    );
}