import { configureStore } from '@reduxjs/toolkit';
import {StateSchema} from './StateSchema';
import {__IS_DEV__} from "../../utils/constance";
import {loginReducer} from "./slices/loginSlice";
import {userReducer} from "./slices/userSlice";
import {signupReducer} from "./slices/signupSlice";
import {profileReducer} from "./slices/profileSlice";
import {changePassReducer} from "./slices/changePassSlice";
import {gameListReducer} from "./slices/gameListSlice";

export function createReduxStore(
    initialState?: StateSchema,
    ) {


    return configureStore<StateSchema>({
        reducer: {
            login: loginReducer,
            signup: signupReducer,
            user: userReducer,
            profile: profileReducer,
            changePass: changePassReducer,
            gameList: gameListReducer,
        },
        devTools: __IS_DEV__,
        preloadedState: initialState,
    });
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch']
