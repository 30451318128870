import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Button, Typography} from "@mui/material";
import {Token} from "../../types/types";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "react";
import {buyToken, getCredit, getMicroloan, repayCredit, repayMicroloan, sellToken} from "../../utils/ws";
import CustomDialog from "./Dialog";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 800,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

const initialPlayerTokens = [
    0, 0, 0, 0,
    0, 0, 0, 0,
    0, 0, 0, 0,
]

interface TokensModalProps {
    data: Array<Token>;
    playerTokensData: Array<number>;
}

export default function TokensModal({data, playerTokensData}: TokensModalProps) {
    const [open, setOpen] = React.useState(false);
    const [tokens, setTokens] = useState<Array<Token>>([]);
    const [playerTokens, setPlayerTokens] = useState<Array<number>>(initialPlayerTokens);

    const handleOpen = () => {
        setTokens(data);
        setPlayerTokens(playerTokensData);
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setPlayerTokens(playerTokensData)
        setTokens(data)
    }, [data, playerTokensData]);

    return (
        <div>
            <Button variant={'contained'} onClick={handleOpen}>
                Tokens
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant={'h4'} sx={{m: 2, flexBasis: '100%', justifyContent: 'center', display: 'flex'}}>Tokens</Typography>
                    <Box sx={{width: '95%', border: '1px solid #eee', height: '1px', backgroundColor: '#eee', mb: 18}}></Box>
                    <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                        {tokens?.map((token, index) => (
                            <Box key={index} sx={{display: 'flex', flexBasis: '9%', mr: 6.5, mb: 15, alignItems: 'center', position: 'relative'}}>
                                <IconButton disabled={playerTokens[index] === 0} onClick={() => sellToken(index)} sx={{height: '50px', padding: '10px', mr: '-10px', zIndex: 50, visibility: index > 9 ? 'hidden' : 'visible'}}>
                                    <RemoveCircleIcon fontSize={'large'}/>
                                </IconButton>
                                <Box sx={{position: 'relative', height: '100px', zIndex: 50}}>
                                    <img
                                        width={'100px'}
                                        height={'100px'}
                                        src={`https://static.farm.thunderstrike.studio/get-rich/tokens/${index}.png`}
                                        srcSet={`https://static.farm.thunderstrike.studio/get-rich/tokens/${index}.png`}
                                        alt={'token'}
                                    />
                                    <Typography sx={{
                                        position: 'absolute',
                                        top: -31,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        color: 'black'
                                    }}>
                                        {playerTokens[index]}
                                    </Typography>
                                </Box>
                                <IconButton onClick={() => buyToken(index)} sx={{height: '50px', padding: '10px', ml: '-10px', zIndex: 50, visibility: index > 9 ? 'hidden' : 'visible'}}>
                                    <AddCircleIcon fontSize={'large'} />
                                </IconButton>
                                <Box sx={{
                                    width: '120px',
                                    height: '135px',
                                    background: '#eee',
                                    zIndex: 1,
                                    position: 'absolute',
                                    right: '-55px',
                                    top: '-75px',
                                    borderRadius: '10%',
                                    padding: '10px',
                                    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                                    visibility: index > 9 ? 'hidden' : 'visible'
                                }}>
                                    <Typography  mb={0.5} align={'right'} fontSize={12}>доступно: {token.quantity}</Typography>
                                    <Typography  mb={0.5} align={'right'} fontSize={12}>дивиденды: {token.dividends}</Typography>
                                    <Typography  mb={0.5} align={'right'} fontSize={12}>цена покупки: {token.buy}</Typography>
                                    <Typography  mb={0.5} align={'right'} fontSize={12}>цена продажи: {token.sell}</Typography>
                                </Box>
                            </Box>
                        ))}
                        <Box sx={{display: 'flex', flexBasis: '9%', mr: 6, mb: 15, alignItems: 'center', position: 'relative'}}>
                            <IconButton
                                disabled={playerTokens[10] === 0}
                                sx={{height: '50px', padding: '10px', mr: '-10px', zIndex: 50, visibility:'visible'}}
                                onClick={() => repayMicroloan()}
                            >
                                <RemoveCircleIcon fontSize={'large'}/>
                            </IconButton>
                            <Box sx={{position: 'relative', height: '100px', zIndex: 50}}>
                                <img
                                    width={'100px'}
                                    height={'100px'}
                                    src={`https://static.farm.thunderstrike.studio/get-rich/tokens/10.png`}
                                    srcSet={`https://static.farm.thunderstrike.studio/get-rich/tokens/10.png`}
                                    alt={'token'}
                                />
                                <Typography sx={{
                                    position: 'absolute',
                                    top: -31,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    color: 'black'
                                }}>
                                    {playerTokens[10]}
                                </Typography>
                            </Box>
                            <IconButton
                                sx={{height: '50px', padding: '10px', ml: '-10px', zIndex: 50, visibility:'visible'}}
                                onClick={() => getMicroloan()}
                            >
                                <AddCircleIcon fontSize={'large'} />
                            </IconButton>
                            <Box sx={{
                                width: '115px',
                                height: '135px',
                                background: '#eee',
                                zIndex: 1,
                                position: 'absolute',
                                right: '-50px',
                                top: '-75px',
                                borderRadius: '10%',
                                padding: '10px',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                                visibility: 'visible'
                            }}>
                                <Typography  mb={0.5} align={'right'} fontWeight={700} fontSize={12}>Микрокредит</Typography>
                                <Typography  mb={0.5} color={'green'} align={'right'} fontSize={10}>Выплата: +500 на баланс</Typography>
                                <Typography  mb={0.5} color={'red'} align={'right'} fontSize={10}>Обязательства: -200 зарплаты</Typography>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex', flexBasis: '9%', mr: 6, mb: 15, alignItems: 'center', position: 'relative'}}>
                            <IconButton
                                disabled={playerTokens[11] === 0}
                                sx={{height: '50px', padding: '10px', mr: '-10px', zIndex: 50, visibility: 'visible'}}
                                onClick={() => repayCredit()}
                            >
                                <RemoveCircleIcon fontSize={'large'}/>
                            </IconButton>
                            <Box sx={{position: 'relative', height: '100px', zIndex: 50}}>
                                <img
                                    width={'100px'}
                                    height={'100px'}
                                    src={`https://static.farm.thunderstrike.studio/get-rich/tokens/11.png`}
                                    srcSet={`https://static.farm.thunderstrike.studio/get-rich/tokens/11.png`}
                                    alt={'token'}
                                />
                                <Typography sx={{
                                    position: 'absolute',
                                    top: -31,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    color: 'black'
                                }}>
                                    {playerTokens[11]}
                                </Typography>
                            </Box>
                            <Box sx={{height: '50px', padding: '10px', ml: '-10px', zIndex: 50, visibility: 'visible', display: 'flex', alignItems: 'center'}}>
                                <CustomDialog display={'flex'} title={'Взять кредит'} text={'* После получения кредита вы пропустите текущий раунд!'} func={() => getCredit()}>
                                    <IconButton sx={{padding: 0, display: 'flex'}}>
                                        <AddCircleIcon fontSize={'large'} />
                                    </IconButton>
                                </CustomDialog>
                            </Box>
                            <Box sx={{
                                width: '115px',
                                height: '135px',
                                background: '#eee',
                                zIndex: 1,
                                position: 'absolute',
                                right: '-50px',
                                top: '-75px',
                                borderRadius: '10%',
                                padding: '10px',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                                visibility: 'visible'
                            }}>
                                <Typography  mb={0.5} align={'right'} fontWeight={700} fontSize={12}>Кредит</Typography>
                                <Typography  mb={0.5} color={'green'} align={'right'} fontSize={10}>Выплата: +1000 на баланс</Typography>
                                <Typography  mb={0.5} color={'red'} align={'right'} fontSize={10}>Обязательства: -200 зарплаты</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}