import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ReactNode, useState} from "react";
import Box from "@mui/material/Box";

interface FactoryDialogProps {
    text: string;
    actionText: string;
    func: (params?: any) => void;
    func2: (params?: any) => void;
    children: ReactNode;
    disabled: boolean;
}

export default function FactoryDialog(props: FactoryDialogProps) {
    const {func, func2, children, disabled, text, actionText} = props;
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        if (!disabled) {
            setOpen(true);
        }
    }

    return (
        <React.Fragment>
            <Box sx={{cursor: 'pointer', width: '100%', height: '100%'}} onClick={handleOpen}>
                {children}
            </Box>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{p: 3}} id="alert-dialog-title">
                    Выберите действие
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{width: '300px'}}>
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{p: 3}}>
                    <Button size={'small'} sx={{mr: 2}} variant={'outlined'} onClick={() => setOpen(false)}>
                        Отмена
                    </Button>
                    <Button size={'small'} variant={'contained'} onClick={() => {func(); setOpen(false)}}>
                        Продать
                    </Button>
                    <Button size={'small'} variant={'contained'} onClick={() => {func2(); setOpen(false)}}>
                        {actionText}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}