import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {AuctionData, LastBetData} from "../../types/types";
import {useEffect, useState} from "react";
import {Button, TextField, Typography} from "@mui/material";
import {doAuctionBet, pass} from "../../utils/ws";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 6,
};

interface AuctionModalProps {
    open: boolean;
    data: AuctionData;
    timer: number;
    lastBet: LastBetData;
    userIndex: number;
}


export default function AuctionModal(props: AuctionModalProps) {
    const [time, setTime] = useState<number>(15);
    const {open, data, timer, lastBet, userIndex} = props;
    const [bet, setBet] = useState<number>(10);

    const [isTimerChange, setIsTimerChange] = useState(false)

    useEffect(() => {
        if (open === false) {
            setTime(15);
            setIsTimerChange(false);
        }

        if (time > 0 && open) {
            setTimeout(() => {
                if (timer === 5 && !isTimerChange) {
                    setTime(4);
                    setIsTimerChange(true);
                } else {
                    setTime(time -1)
                }
            }, 1000);
        }
    }, [time, open, timer, isTimerChange, data]);

    const betAction = () => {
        doAuctionBet(bet);
        setBet(10);
    }

    const outbid = () => {
        if (lastBet?.amountInterruption !== undefined) {
            doAuctionBet(lastBet?.amountInterruption);
            setBet(10);
        }

        if (lastBet?.amountInterruption === undefined && data?.startingBid) {
            doAuctionBet(data?.startingBid);
            setBet(10);
        }

    }

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <Typography variant={'h4'}>
                            До конца аукциона: {time}
                        </Typography>
                        <Box sx={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-around'}}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', alignItems: 'center'
                            }}>
                                <TextField
                                    sx={{mb: '1rem'}}
                                    value={bet}
                                    type={"number"}
                                    onChange={(e) =>
                                        setBet(Number(e.target.value))
                                    }
                                    label={'bet'}
                                />

                                <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                    <Button sx={{width: '40%'}} variant={'outlined'} onClick={() => pass()}>
                                        Pass
                                    </Button>
                                    <Button disabled={userIndex === lastBet?.currentPlayer} sx={{width: '40%'}} variant={'contained'} onClick={betAction}>
                                        Bet
                                    </Button>
                                </Box>
                                <Box sx={{display: 'flex', width: '100%', mt: 2, alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Button disabled={userIndex === lastBet?.currentPlayer} sx={{width: '30%'}} variant={'contained'} onClick={outbid}>
                                        Outbid
                                    </Button>
                                    <Typography sx={{width: '60%'}}>
                                         ставка: {lastBet?.amountInterruption || data?.startingBid}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', height: '100px', width: '75px'}}>
                                <img
                                    width={'100%'}
                                    height={'100%'}
                                    src={`https://static.farm.thunderstrike.studio/get-rich/deck-lots/${data?.prize}.png`}
                                    srcSet={`https://static.farm.thunderstrike.studio/get-rich/deck-lots/${data?.prize}.png`}
                                    alt={'prize'}
                                />
                            </Box>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', alignItems: 'start'
                            }}>
                                <Typography mb={2} fontSize={16}>Последняя ставка: {lastBet?.bet}</Typography>
                                <Typography mb={2} fontSize={16}>Нужно чтобы перебить: {lastBet?.amountInterruption}</Typography>
                                <Typography fontSize={16}>Выигрывает игрок №{lastBet?.currentPlayer}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}