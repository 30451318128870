import { createAsyncThunk } from '@reduxjs/toolkit';
import {$api} from "../api/api";
import {ThunkConfig} from "../config/store/StateSchema";
import {GameListSchema} from "../config/store/types/GameListSchema";
import {gameListActions} from "../config/store/slices/gameListSlice";
import {userActions} from "../config/store/slices/userSlice";

export const getGameListReq = createAsyncThunk<GameListSchema, null, ThunkConfig<string>>(
    'getGameListReq',
    async (data, thunkAPI) => {
        const { dispatch, rejectWithValue } = thunkAPI;

        try {
            const response = await $api.get<GameListSchema>('/session-data');

            if (response.status === 401) {
                dispatch(userActions.logout)
            }

            if (!response.data) {
                throw new Error();
            }

            dispatch(gameListActions.updateData(response.data));

            return response.data;
        } catch (e) {
            console.log(e);
            return rejectWithValue('error');
        }
    },
);
