import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {TextField} from "@mui/material";
import {useCallback} from "react";
import {useAppDispatch} from "../../hooks/redux";
import {useSelector} from "react-redux";
import {getOldPassword} from "../../config/store/selectors/changePassword/getOldPassword";
import {getNewPassword} from "../../config/store/selectors/changePassword/getNewPassword";
import {changePassActions} from "../../config/store/slices/changePassSlice";
import {changePassword} from "../../service/changePassword";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

export default function ChangePasswordModal() {
    const [open, setOpen] = React.useState(false);
    // const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const oldPassword = useSelector(getOldPassword);
    const newPassword = useSelector(getNewPassword);
    // const isLoading = useSelector(getChangePassIsLoading);
    // const error = useSelector(getChangePasswordError);
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');

    function handleOpen() {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const onChangeOldPassword = useCallback((value: string) => {
        dispatch(changePassActions.setOldPassword(value));
    }, [dispatch]);

    const onChangeNewPassword = useCallback((value: string) => {
        dispatch(changePassActions.setNewPassword(value));
    }, [dispatch]);

    const onChangePasswordClick = useCallback(async () => {
        if  (newPassword === repeatPassword) {
            await dispatch(changePassword({ newPassword, oldPassword }));
        } else {
            alert('Password mismatch')
        }
    }, [dispatch, newPassword, oldPassword, repeatPassword]);

    return (
        <div>
            <Button variant="contained" onClick={handleOpen}>
                Change password
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={oldPassword}
                            type={"text"}
                            onChange={(e) =>
                                onChangeOldPassword(e.target.value)
                            }
                            label={'old password'}
                        />
                        <TextField
                            value={newPassword}
                            type={"text"}
                            onChange={(e) =>
                                onChangeNewPassword(e.target.value)
                            }
                            label={'new password'}
                        />
                        <TextField
                            value={repeatPassword}
                            onChange={(e) =>
                                setRepeatPassword(e.target.value)
                            }
                            label={'repeat password'}
                        />
                        <Button onClick={onChangePasswordClick} size={'large'} variant="contained">
                            Change password
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}