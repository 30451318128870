import React from 'react';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {render} from "react-dom";
import {ThemeProvider} from "@mui/material";
import {theme} from "./config/theme/theme";
import './config/i18n/i18n';
import {StoreProvider} from "./providers/StoreProvider";


render(
    <StoreProvider>
        <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
        </BrowserRouter>
    </StoreProvider>,
    document.getElementById('root'),
);