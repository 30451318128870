import {memo, useCallback, useEffect} from "react";
import Box from "@mui/material/Box";
import {useTheme} from "../hooks/theme";
import userImg from "../assets/img/user.png"
import {TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../hooks/redux";
import {session} from "../service/session";
import {useSelector} from "react-redux";
import {getProfileEmail} from "../config/store/selectors/profile/getProfileEmail";
import {getProfileRating} from "../config/store/selectors/profile/getProfileRating";
import {getProfileNickname} from "../config/store/selectors/profile/getProfileNickname";
import ChangePasswordModal from "../components/ui/ChangePasswordModal";

const Profile = memo(() => {
    const theme = useTheme()
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const email = useSelector(getProfileEmail);
    const rank = useSelector(getProfileRating);
    const nickname = useSelector(getProfileNickname);

    const getProfileData = useCallback(async () => {
        await dispatch(session())
    }, [dispatch]);

    useEffect(() => {
        getProfileData()

    }, [getProfileData]);


    return (
        <Box
            sx={{
                backgroundColor: theme.palette.primary.light,
                width: '100%',
                height: '85vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'

        }}>
            <Box sx={{borderRadius: '50%', width: '20%', mb: 4, position: 'relative'}}>
                <img
                    width={'100%'}
                    height={'100%'}
                    src={userImg}
                    srcSet={userImg}
                    alt={'profile img'}
                />
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '40%'}}>
                <TextField
                    value={email}
                    label="Email"
                />
                <Typography>
                    {t('YOUR_RANK')}: {rank}
                </Typography>
                <TextField
                    value={nickname}
                    label="Nickname"
                />
            </Box>
            <Box sx={{
                display: 'flex',
                width: '20%',
                height: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
                m: '3em auto 1em auto',
            }}>
                <ChangePasswordModal />
            </Box>
        </Box>
    );
});

export default Profile;