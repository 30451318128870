import {memo, useCallback, useEffect} from "react";
import {Box, Button, Typography} from "@mui/material";
import {useAppDispatch} from "../hooks/redux";
import {useSelector} from "react-redux";
import {getGameList} from "../config/store/selectors/gameList/getGameList";
import {getGameListReq} from "../service/gameList";
import {useTheme} from "../hooks/theme";
import PeopleIcon from '@mui/icons-material/People';
import CreateGameModal from "../components/ui/CreateGameModal";
import {useNavigate} from "react-router-dom";
import AddCardIcon from '@mui/icons-material/AddCard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LoopIcon from '@mui/icons-material/Loop';

const MainPage = memo(() => {
    const dispatch = useAppDispatch();
    const list = useSelector(getGameList);
    const theme = useTheme();
    const navigate = useNavigate();

    const getGamesData = useCallback(async () => {
        await dispatch(getGameListReq())
    }, [dispatch]);

    useEffect(() => {
        getGamesData()
    }, [getGamesData]);

    return (
        <Box sx={{
            width: '100%',
            height: '80vh',
            display: 'flex',
            mt: '8vh',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
        }}>
            <Typography mt={5} variant={'h4'}>
                Game List
            </Typography>

            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                mt: 3,
            }}>
                <Box sx={{
                    display: 'flex',
                    mt: 2,
                    mb: 2,
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <Button onClick={() => navigate('/room/game_id=0&max_players=3')} sx={{mr: 2}} variant={"outlined"}>Quick search</Button>
                    <CreateGameModal />
                </Box>
                {list === null ? null : list?.map((item) => (
                    <Box key={item.sessionID}
                         sx={{
                             borderRadius: 3,
                             backgroundColor: theme.palette.primary.light,
                             p: 2,
                             m: 3,
                             display: 'flex',
                             width: '40%',
                             justifyContent: 'space-between',
                             alignItems: 'center',
                        }}>
                        <Typography fontSize={20} sx={{ml: 2}}>
                            № {item.sessionID}
                        </Typography>
                        <Typography sx={{display: 'flex', alignItems: 'center', mr: 5}} fontSize={20}>
                            <PeopleIcon sx={{mr: 1}} /> {item.currentPlayers}/{item.maxPlayers}
                        </Typography>
                        <Typography sx={{display: 'flex', alignItems: 'center', mr: 5}} fontSize={20}>
                            <AddCardIcon sx={{mr: 1}} /> {item.endGameSalary}
                        </Typography>
                        {
                            item.endGameStep ?
                                <Typography sx={{display: 'flex', alignItems: 'center', mr: 5}} fontSize={20}>
                                    <AccessTimeIcon sx={{mr: 1}} /> {item.endGameStep}
                                </Typography>
                                : null
                        }
                        {
                            item.endGameTime ?
                                <Typography sx={{display: 'flex', alignItems: 'center', mr: 5}} fontSize={20}>
                                    <LoopIcon sx={{mr: 1}} /> {item.endGameTime}
                                </Typography>
                                : null
                        }
                        <Button onClick={() => navigate(
                            `/room/game_id=${item.sessionID}&max_players=${item.maxPlayers}&end_salary=${item.endGameSalary}&end_time=${item.endGameTime}&end_step=${item.endGameStep}`
                        )}
                                sx={{mr: 3, fontSize: 16}} variant={'contained'}
                        >
                            Join
                        </Button>
                    </Box>
                ))}
            </Box>
        </Box>
    );
});

export default MainPage;