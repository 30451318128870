import { createAsyncThunk } from '@reduxjs/toolkit';
import {userActions} from "../config/store/slices/userSlice";
import {$api} from "../api/api";
import {Msg} from "../types/types";

export const logout = createAsyncThunk(
    'logout',
    async (authData, thunkAPI) => {
        const { dispatch, rejectWithValue } = thunkAPI;

        try {

            const response = await $api.get<Msg>('/logout');

            if (response.status !== 200) {
                throw new Error();
            }


            dispatch(userActions.logout());

            return response.data;
        } catch (e) {
            console.log(e);
            return rejectWithValue('error');
        }
    },
);
