import React from 'react';
import {Box, Collapse, List, ListItemButton, ListItemText, Typography} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {theme} from "../../config/theme/theme";
import CardImage from "./CardImage";
import {getStreetQuarter} from "../../utils/utils";
import ZoomCard from "./ZoomCard";
import Tooltip from "@mui/material/Tooltip";
import {Token} from "../../types/types";

interface TradeItemProps {
    title: string;
    arr: number[];
    type: ItemType;
    addFunc?: (card: number) => void;
    tokensData?: Token[];
}

type ItemType = 'street' | 'building' | 'characters' | 'tokens';
type CardsType = 'buildings' | 'characters';

const TradeItem = (props: TradeItemProps) => {
    const {title, arr, type, addFunc, tokensData} = props;
    const [open, setOpen] = React.useState(false);

    function handleClick() {
        setOpen(!open);
    }

    const streetsMap = () => {
        return (
            arr?.map((item) => (
                <ListItemButton onClick={() => addFunc(item)} key={item} sx={{ pl: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                        sx={{
                            width: '85px',
                            height: '125px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bgcolor: getStreetQuarter(item).color,
                            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
                        }}
                    >
                        <Typography fontWeight={600} fontStyle={'italic'} align={'center'} sx={{wordBreak: 'break-word', pl: 1, pr: 1}} fontSize={14}>
                            {getStreetQuarter(item).name}
                        </Typography>
                        <Typography fontWeight={600} fontStyle={'italic'} align={'center'} sx={{wordBreak: 'break-word', pl: 1, pr: 1}} fontSize={14}>
                            {`№${item}`}
                        </Typography>
                    </Box>
                </ListItemButton>
            ))
        )
    }

    const cardsMap = (cardVariant: CardsType) => {
        return (
            arr?.map((item) => (
                <ListItemButton
                    className={'listItem'}
                    onClick={(e) => {
                        if (e.target instanceof HTMLElement) {
                            if (e.target.classList.contains('listItem')) {
                                addFunc(item)
                            }
                        }
                    }}
                    key={item}
                    sx={{ pl: 4, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                    <Box
                        sx={{
                            width: '85px',
                            height: '125px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            m: 1,
                            zIndex: 100
                        }}
                    >
                        {
                            cardVariant === 'characters'
                                ? <ZoomCard card={<CardImage alt={'character'} deck={`deck-characters/${item}`} />} />
                                : <ZoomCard card={<CardImage alt={'industry'} deck={`deck-industry/${item}`} />} />
                        }
                    </Box>
                </ListItemButton>
            ))
        )
    }

    const getInfoToken = (index: number) => {
        return `Дивиденды: ${tokensData[index]?.dividends} Цена: ${tokensData[index]?.buy}`;
    }

    const tokensMap = () => {
        return (
            arr?.map((item, index) => (
                item !== 0 ?
                <ListItemButton onClick={() => addFunc(index)} key={index} sx={{ pl: 4, pb: 1, pt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Tooltip placement={'top'} title={getInfoToken(index)}>
                        <Box
                            sx={{
                                width: '75px',
                                height: '75px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                                m: 1,
                            }}
                        >
                            <CardImage alt={'token'} deck={`tokens/${index}`}/>
                            <Typography sx={{
                                position: 'absolute',
                                top: -31,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black'
                            }}>
                                {item}
                            </Typography>
                        </Box>
                    </Tooltip>
                </ListItemButton>
                : null
            ))
        )
    }

    const getMap = (type: ItemType) => {
        switch (type) {
            case "street": return streetsMap();
            case "building": return cardsMap('buildings');
            case "characters": return cardsMap('characters');
            case "tokens": return tokensMap();
        }

    }

    return (
        <Box sx={{width: '100%'}}>
            <ListItemButton
                color={theme.palette.primary.main}
                sx={{
                    width: '100%',
                    height: '50px',
                    borderTop: '2px solid #E2D6CE',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 20,
                    bgcolor: theme.palette.primary.main,
                    color: 'white',
                    '&:hover': {
                        color: 'white',
                        backgroundColor: theme.palette.primary.main,
                    }
            }}
                onClick={handleClick}
            >
                <ListItemText>
                    <Typography fontSize={20}>
                        {title}
                    </Typography>
                </ListItemText>
                {open ? <ExpandLess sx={{fontSize: 24}} /> : <ExpandMore sx={{fontSize: 24}} />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{overflow: 'auto', maxHeight: '300px'}}>
                    {getMap(type)}
                </List>
            </Collapse>
        </Box>
    );
};

export default TradeItem;