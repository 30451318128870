import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {chooseCharacter, pass} from "../../utils/ws";
import CardImage from "./CardImage";

interface ProtectDialogProps {
    data: any;
    openModal: boolean;
    variant: number;
}

export default function ProtectDialog({data, openModal, variant}: ProtectDialogProps) {
    const [time, setTime] = useState<number>(12);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (openModal === false) {
            setOpen(false);
            setTime(12);
        }

        if (openModal) {
            setOpen(true);
        }

        if (time > 0 && openModal) {
            setTimeout(() => {
                setTime(time -1)
            }, 1000);
        }
    }, [time, openModal]);

    const getTitle = () => {
        if (variant === 1) {
            return 'Использовать карту шулера ?'
        }

        if (variant === 2) {
            return 'Использовать карту пожарного ?'
        }

        if (variant === 3) {
            return 'Использовать карту доктора ?'
        }
    }

    const useCharacter = () => {
        switch (variant) {
            case 1: chooseCharacter(data.cheater); setOpen(false)
                break;
            case 2: chooseCharacter(data.fireman); setOpen(false)
                break;
            case 3: chooseCharacter(data.doctor); setOpen(false)
                break;
        }
    }

    const information = () => {
        if (variant === 1 && data) {
            return (
                <>
                    <Typography>
                        {`Выпало ${data.winNumber} ${data.color}`}
                    </Typography>
                    <Typography>
                        Ваши ставки:
                    </Typography>
                    {data.yourBid.map((bet) => (
                        <Typography key={bet.value}>
                            {
                                bet.value === 13
                                    ? `Красное - ставка: ${bet.sum}`
                                    : bet.value === 12
                                        ? `Чёрное - ставка: ${bet.sum}`
                                        : `${bet.value} - ставка: ${bet.sum}`
                            }
                        </Typography>
                    ))}
                </>
            )
        }

        if (variant === 2 && data) {
            return (
                <>
                    <Typography>
                        {`Оппонент пытается уничтожить ваше здание на улице ${data.street}`}
                    </Typography>
                    <Typography>
                        Здания на улице:
                    </Typography>
                    <Box sx={{display: 'flex', height: '250px', mt: 2, mb: 1}}>
                        <Box sx={{mr: 2, width: '100%', height: '250px'}}>
                            <CardImage  alt={'hotel'} deck={`deck-lots/${data.hotel}`}/>
                        </Box>
                        <Box sx={{width: '100%', height: '250px'}}>
                            <CardImage  alt={'factory'} deck={`deck-industry/${data.industries}`}/>
                        </Box>
                    </Box>
                </>
            )
        }

        if (variant === 3 && data) {
            return (
                <>
                    <Typography>
                        {`Оппонент пытается убить вашего рабочего. Использовать доктора ?`}
                    </Typography>
                    <Typography>
                        Рабочий:
                    </Typography>
                    <Box sx={{display: 'flex', height: '250px', justifyContent: 'center', mt: 2, mb: 1}}>
                        <Box sx={{width: '150px', height: '250px'}}>
                            <CardImage  alt={'worker'} deck={`deck-characters/${data.worker}`}/>
                        </Box>
                    </Box>
                </>
            )
        }
    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {getTitle()}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Время для принятия решения: {time}
                    </DialogContentText>
                    {information()}
                </DialogContent>
                <DialogActions>
                    <Button sx={{mr: 2}} variant={'outlined'} onClick={() => {pass(); setOpen(false)}}>
                        Нет
                    </Button>
                    <Button variant={'contained'} onClick={useCharacter}>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}