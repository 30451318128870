import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

export default function CreateGameModal() {
    const [open, setOpen] = React.useState(false);
    const [maxPlayers, setMaxPlayers] = React.useState('2');
    const [endGameSalary, setEndGameSalary] = React.useState('9000');
    const [endGameTime, setEndGameTime] = React.useState(null);
    const [endGameStep, setEndGameStep] = React.useState(null);
    const navigate = useNavigate();

    const changeUsersCount = (
        event: React.MouseEvent<HTMLElement>,
        usersCount: string,
    ) => {
        setMaxPlayers(usersCount);
    };

    const changeEndGameSalary = (
        event: React.MouseEvent<HTMLElement>,
        salary: string,
    ) => {
        setEndGameSalary(salary);
    };

    const changeEndGameTime = (
        event: React.MouseEvent<HTMLElement>,
        time: string,
    ) => {
        setEndGameTime(time);
    };

    const changeEndGameStep = (
        event: React.MouseEvent<HTMLElement>,
        step: string,
    ) => {
        setEndGameStep(step);
    };

    const childrenUsersCount = [
        <ToggleButton value="2" key="2">2</ToggleButton>,
        <ToggleButton value="3" key="3">3</ToggleButton>,
        <ToggleButton value="4" key="4">4</ToggleButton>,
        <ToggleButton value="5" key="5">5</ToggleButton>,
    ];

    const childrenSalary = [
        <ToggleButton value="6000" key="6000">6000</ToggleButton>,
        <ToggleButton value="9000" key="9000">9000</ToggleButton>,
        <ToggleButton value="12000" key="12000">12000</ToggleButton>,
        <ToggleButton value="15000" key="15000">15000</ToggleButton>,
    ];

    const childrenTime = [
        <ToggleButton value="30" key="30">30</ToggleButton>,
        <ToggleButton value="60" key="60">60</ToggleButton>,
        <ToggleButton value="120" key="120">120</ToggleButton>,
        <ToggleButton value="180" key="180">180</ToggleButton>,
    ];

    const childrenStep = [
        <ToggleButton value="25" key="25">25</ToggleButton>,
        <ToggleButton value="50" key="50">50</ToggleButton>,
        <ToggleButton value="100" key="100">100</ToggleButton>,
        <ToggleButton value="150" key="150">150</ToggleButton>,
    ];

    const controlUsersCount = {
        value: maxPlayers,
        onChange: changeUsersCount,
        exclusive: true,
    };

    const controlSalary = {
        value: endGameSalary,
        onChange: changeEndGameSalary,
        exclusive: true,
    };

    const controlTime = {
        value: endGameTime,
        onChange: changeEndGameTime,
        exclusive: true,
    };

    const controlStep = {
        value: endGameStep,
        onChange: changeEndGameStep,
        exclusive: true,
    };

    function handleOpen() {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const getLink = () => {
        let result = `/room/game_id=-1&max_players=${maxPlayers}&end_salary=${endGameSalary}`
        if (endGameTime) {
            result = result + `&end_time=${endGameTime}`
        }
        if (endGameStep) {
            result = result + `&end_step=${endGameStep}`
        }
        return result
    }

    return (
        <div>
            <Button variant="contained" onClick={handleOpen}>
                Create game
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography variant={'h6'} mr={2}>
                                Choose users count -
                            </Typography>
                            <ToggleButtonGroup size="medium" {...controlUsersCount}>
                                {childrenUsersCount}
                            </ToggleButtonGroup>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography variant={'h6'} mr={2}>
                                Choose salary for win -
                            </Typography>
                            <ToggleButtonGroup size="medium" {...controlSalary}>
                                {childrenSalary}
                            </ToggleButtonGroup>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography variant={'h6'} mr={2}>
                                Choose time when game finish -
                            </Typography>
                            <ToggleButtonGroup size="medium" {...controlTime}>
                                {childrenTime}
                            </ToggleButtonGroup>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography variant={'h6'} mr={2}>
                                Select the step at which the game ends -
                            </Typography>
                            <ToggleButtonGroup size="medium" {...controlStep}>
                                {childrenStep}
                            </ToggleButtonGroup>
                        </Box>
                        <Button onClick={() => navigate(getLink())} size={'large'} variant="contained">
                            Create game
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}