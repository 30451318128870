import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSchema } from '../types/UserSchema';
import {deleteAllCookies} from "../../../utils/utils";

const initialState: UserSchema = {
    authData: undefined
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthData: (state: UserSchema, action: PayloadAction<string>) => {
            state.authData = action.payload;
        },
        initAuthData: (state: UserSchema) => {
            const user = document.cookie;
            if (user && user !== '') {
                state.authData = user;
            } else {
                state.authData = null;
            }
        },
        logout: (state: UserSchema) => {
            state.authData = null;
            deleteAllCookies();
        },
    },
});

export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
