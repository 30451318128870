import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ReactNode, useState} from "react";
import Box from "@mui/material/Box";

interface CustomDialogProps {
    title: string;
    func: (params?: any) => void;
    text?: string;
    children: ReactNode;
    display?: string;
}

export default function CustomDialog(props: CustomDialogProps) {
    const {title, text, func, children, display = 'block'} = props;
    const [open, setOpen] = useState<boolean>(false);

    return (
        <React.Fragment>
            <Box sx={{cursor: 'pointer', width: '100%', height: '100%', display: display}} onClick={() => setOpen(true)}>
                {children}
            </Box>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{width: '300px'}}>
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size={'small'} sx={{mr: 2}} variant={'outlined'} onClick={() => setOpen(false)}>
                        Нет
                    </Button>
                    <Button size={'small'} variant={'contained'} onClick={() => {func(); setOpen(false)}} autoFocus>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}