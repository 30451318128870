import {memo} from "react";

const About = memo(() => {
    return (
        <div>
            RoomList
        </div>
    );
});

export default About;