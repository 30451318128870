import React, {useEffect} from 'react';
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import AppRouter from "../../providers/router/AppRouter";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {getUserState} from "../../config/store/selectors/user/getUserState";
import NotAuthRouter from "../../providers/router/NotAuthRouter";
import {useAppDispatch} from "../../hooks/redux";
import {userActions} from "../../config/store/slices/userSlice";
import {closeWS, socket} from "../../utils/ws";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
    const userState = useSelector(getUserState)
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(userActions.initAuthData())
    }, [dispatch])

    /* eslint-disable */
    useEffect(() => {
        if (userState.authData === null && location.pathname !== '/signup') {
            navigate('login');
        }
    }, [userState, navigate]);
    /* eslint-enable */

    useEffect(() => {
        if (socket && !location.pathname.includes('room')) {
            closeWS()
        }
    }, [location]);

    if (location.pathname.includes('room')) {
        return (
            <div className={'app'}>
                <Navbar/>
                <ToastContainer />
                <AppRouter/>
            </div>
        );
    } else if (userState.authData === null) {
        return (
            <>
                <NotAuthRouter/>
            </>
        );
    } else {
        return (
            <div className={'app'}>
                <Navbar/>
                <AppRouter/>
                <Footer/>
            </div>
        );
    }

};

export default Layout;