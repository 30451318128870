import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {SignupSchema} from "../types/SignupSchema";
import {signup} from "../../../service/signup";

const initialState: SignupSchema = {
    email: '',
    password: '',
    nickname: '',
    isLoading: false,
    error: '',
};


export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        setEmail: (state: SignupSchema, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setNickname: (state: SignupSchema, action: PayloadAction<string>) => {
            state.nickname = action.payload;
        },
        setPassword: (state: SignupSchema, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signup.pending, (state: SignupSchema) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(signup.fulfilled, (state: SignupSchema, action) => {
                state.isLoading = false;
            })
            .addCase(signup.rejected, (state: SignupSchema, action) => {
                state.isLoading = false;
                state.error = action.payload.msg;
            });
    },
});

export const { actions: signupActions } = signupSlice;
export const { reducer: signupReducer } = signupSlice;
