import {memo} from "react";

const NotFound = memo(() => {
    return (
        <div>
            Not Found
        </div>
    );
});

export default NotFound;