import {Bounce, toast} from "react-toastify";
import {Proposal} from "../types/types";

export let socket;

export function initWS(roomData: string) {
    if (socket && socket.readyState === 1) {
        closeWS();
    }
    socket = new WebSocket(`ws${window.location.protocol === 'https:' ? 's' : ''}://${window.location.host}/api/ws${roomData}`);

    socket.onopen = function() {
        toast.success('Соединение установлено', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
        console.log('Соединение установлено');
    };

    socket.onclose = function(event) {
        toast.error('Соединение закрыто', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
        console.log('Соединение закрыто');
    };

    socket.onerror = function(error) {
        console.log(`Ошибка: ${error.message}`);
    };
}

export function closeWS() {
    const data = {
        type: "exit",
        message: {out: true}
    }
    socket.send(JSON.stringify(data))
}

export function pass() {
    const data = {
        type: "move",
        message: {x: 0, y: 0, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function buyStreet(y: number) {
    const data = {
        type: "move",
        message: {x: 1, y, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function doAuctionBet(y: number) {
    const data = {
        type: "move",
        message: {x: 3, y, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function doCasinoBet(y: number, z: number) {
    const data = {
        type: "move",
        message: {x: 5, y, z}
    }
    socket.send(JSON.stringify(data))
}

export function sendMsgToChat(msg: string) {
    const data = {
        type: "chat",
        message: {
            message: msg
        }
    }
    socket.send(JSON.stringify(data))
}

export function chooseCharacter(cardIndex: number, aditionalData?: number) {
    const data = {
        type: "move",
        message: {x: 15, y: cardIndex + 1 , z: aditionalData ? aditionalData : 0}
    }
    socket.send(JSON.stringify(data))
}

export function fireWorker(numWorker: number) {
    const data = {
        type: "move",
        message: {x: 16, y: numWorker, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function buildHotel(streetIndex: number) {
    const data = {
        type: "move",
        message: {x: 4, y: streetIndex, z: 0}
    }
    socket.send(JSON.stringify(data))
}


export function buildFactory(streetIndex: number, factoryId: number) {
    const data = {
        type: "move",
        message: {x: 6, y: streetIndex, z: factoryId}
    }
    socket.send(JSON.stringify(data))
}

export function sellIndustry(streetIndex: number, sellOrReserve: number) {
    const data = {
        type: "move",
        message: {x: 7, y: streetIndex, z: sellOrReserve}
    }
    socket.send(JSON.stringify(data))
}

export function sellIndustryFromReserve(cardIndex: number) {
    const data = {
        type: "move",
        message: {x: 25, y: cardIndex, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function buyToken(tokenIndex: number) {
    const data = {
        type: "move",
        message: {x: 2, y: tokenIndex, z: 1}
    }
    socket.send(JSON.stringify(data))
}

export function sellToken(tokenIndex: number) {
    const data = {
        type: "move",
        message: {x: 8, y: tokenIndex, z: 1}
    }
    socket.send(JSON.stringify(data))
}

export function chooseQuarter(quarter: number) {
    const data = {
        type: "move",
        message: {x: 17, y: quarter, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function choosePlayer(playerIndex: number) {
    const data = {
        type: "move",
        message: {x: 18, y: playerIndex, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function destroyBuilding(buildId: number, hotelOrFactory: number) {
    const data = {
        type: "move",
        message: {x: 19, y: buildId, z: hotelOrFactory}
    }
    socket.send(JSON.stringify(data))
}

export function tricksterAction(numWorker: number, indexPlayer: number) {
    const data = {
        type: "move",
        message: {x: 20, y: numWorker, z: indexPlayer}
    }
    socket.send(JSON.stringify(data))
}

export function assassinAction(numWorker: number, indexPlayerOrQuarter: number) {
    const data = {
        type: "move",
        message: {x: 21, y: numWorker, z: indexPlayerOrQuarter}
    }
    socket.send(JSON.stringify(data))
}

export function buyStreetNotary(streetIndex: number) {
    const data = {
        type: "move",
        message: {x: 22, y: streetIndex, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function getMicroloan() {
    const data = {
        type: "move",
        message: {x: 9, y: 0, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function repayMicroloan() {
    const data = {
        type: "move",
        message: {x: 10, y: 0, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function getCredit() {
    const data = {
        type: "move",
        message: {x: 11, y: 0, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function repayCredit() {
    const data = {
        type: "move",
        message: {x: 12, y: 0, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function startRaid(streetIndex: number, zollars: number) {
    const data = {
        type: "move",
        message: {x: 13, y: streetIndex, z: zollars}
    }
    socket.send(JSON.stringify(data))
}

export function payRansomForStreet() {
    const data = {
        type: "move",
        message: {x: 14, y: 0, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function blackBrokerOffer(player: number, tokens: number[]) {
    const data = {
        type: "broker",
        message: {player, tokens}
    }
    console.log('data was send', data)
    socket.send(JSON.stringify(data))
}

export function startTrade(indexPlayer: number) {
    const data = {
        type: "move",
        message: {x: 23, y: indexPlayer, z: 0}
    }
    socket.send(JSON.stringify(data))
}

export function trade(body: Proposal, type: string) {
    const data = {
        type: type,
        message: body
    }
    socket.send(JSON.stringify(data))
}
